import styled, { css } from "styled-components";
import colors from 'components/Theme/colors'
import { device } from 'components/Theme/device'
import { Field } from 'formik'

export const DrinkModalElem = styled.div`
    text-align: center; 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    &.show-overlay{
        pointer-events: all;
        opacity: 1;
        z-index: 99999;
    }
`

export const NotifyWrap = styled.div`
    padding: 0 30px 30px;
`

export const Wrap = styled.div`
    display: flex;
    align-items: center;
`

export const CustomModal = styled.div`
    max-width: 720px;
    width: 100%;
    border-radius: 30px;
    background-color: white;
    overflow: hidden;
    position: relative;

    #close{
        z-index: 999;
        position:absolute;
        right: 15px;
        top: 15px;
        border-radius: 25px;
        height: 40px;
        width: 40px;
        background-color: white;
        border: none;
        background-image: url('/assets/icons/close.svg');
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .modal-header{
        display: flex;
        flex-direction: column;
        padding: 30px;
        text-align: left;
        position: relative;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .modal-main{
        padding: 30px 30px 15px;
        text-align: left;
        .modal-ingredients{
            p:first-of-type{
                font-weight: 700;
                margin-bottom: 0;
            }
        }
        .modal-nutrition{
            ul{
                padding: 0;
                list-style: none;
                li{
                    margin-bottom: 5px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    font-weight: 700;
                }
            }
        }
    }
    .know-more{
        padding: 0 30px;
        color: ${colors.cream};
        width: 100%;
        text-align: left;
        font-weight: 700
    }
    .modal-footer{
        border: none;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 30px 30px;
        > * {
            width: calc(50% - 15px);
            *{
                width: 100%;
            }
        }
    }

    @media ${device.tablet} {
        max-width: 90%;
    }

    @media ${device.tablet} {
        width: 100%;
        max-width: 100%;
        height: 100%;
        border-radius: 0;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::-webkit-scrollbar {
            display: none;
            opacity: 0;
        }

        &::-webkit-scrollbar-track {
            display: none;
            opacity: 0;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
            opacity: 0;
        }

        & .modal-header {
            & img {
                transform: none;
                width: 100%;
                height: 100%;
                transform: scaleX(-1);
                object-fit: cover;
                filter: blur(2px);
                top: 0;
                z-index: 1;
            }
            > p {
                margin: 0;
                z-index: 2;
                max-width: 70%;

                &:first-of-type{
                    font-size: 24px;
                    line-height: 1;
                    padding-bottom: 20px;
                    z-index: 2;
                }
            }
        }

        .modal-footer{
            flex-direction: column;
            > * {
                width: 100%;
                *{
                    width: 100%;
                }
            }
        }
    }
`

export const Article = styled.article`
`

export const Footer = styled.footer`
`

export const HeaderBg = styled.div`
    padding: 10px 15px;
    border-radius: 5px;
    background: rgba(255, 255, 255, .7);
    z-index: 2;

    & p {
        max-width: 100%;
        margin: 0;
        
        &:first-of-type{
            font-size: 26px;
            padding-bottom: 5px;
            line-height: 1.2;
        }
    }
`

export const BackButton = styled.button`
    padding: 10px 25px;
    min-width: 240px;
    border: 2px solid ${colors.primary};
    box-sizing: border-box;
    border-radius: 118.607px;
    font-weight: 600;
    font-size: 17px;
    outline: none;
    cursor: pointer;
    line-height: 20px;
    letter-spacing: 0.13em;
    color: ${colors.primary};
    background-color: ${colors.white};
`

export const CheckboxInput = styled(Field)`
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
        &:before {
            border: 3px solid ${colors.cream};
        }
        &:after {
            opacity: 1;
            transform: scale(1,1);
        }
    }
`;

export const Label = styled.label`
    color: ${colors.black};
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 100%;
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Checkmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;

    &:before {
        content: '';
        display: block; 
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        margin-right: 5px;
        width: 27px;
        height: 27px;
        border: 3px solid ${colors.grey4};
        background: #fff;
    }
    &:after {
        content: '';
        display: block; 
        width: 13px;
        height: 13px;
        background: ${colors.cream};
        position: absolute;
        border-radius: 50%;
        top: 7px;
        left: 7px;
        opacity: 0;
        transform: scale(0,0); 
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }

    @media ${device.tablet} {
        &:before {
            top: -5px;
            width: 25px;
            height: 25px;
        }

        &:after {
            width: 10px;
            height: 10px;
            top: 2.5px;
        }
    }

    @media ${device.mobileL} {
        &:before {
            top: -2px;
        }

        &:after {
            width: 10px;
            height: 10px;
            top: 5px;
        }
    }
`;

export const CheckLabel = styled.span`
    padding-left: 45px;
    line-height: 1.2;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 0.13em;
    color: ${colors.black};

    @media ${device.mobileL} {
        padding-left: 40px;
        font-size: 16px;
    }
`;

export const Hidden = styled.button`
    display: none;
`;

export const CheckboxPrice = styled.span`
    font-weight: normal;
    font-size: 20px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.grey5};
    padding-right: 20px;

    @media ${device.mobileL} {
        padding-right: 0;
        font-size: 16px;
    }
`;

export const Group = styled.div`
    max-height: 300px;
    // overflow: auto;
    &.extra-options{
        overflow: scroll;
    }
`

export const RouteWrap = styled.div`
    padding: 30px 0;
    justify-content: space-between;

    ${props => props.flex && css`
        display: flex;
        padding: 30px 33.5px;
    `}

    @media ${device.tablet} {
        ${props => props.flex && css`
            flex-direction: column;
        `}
    }
`

export const Title = styled.h3`
    font-weight: bold;
    font-size: 35px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.black};
    margin: 0;

    @media ${device.tablet} {
        font-size: 20px;
    }

    @media ${device.mobileL} {
        font-size: 17px;
    }
`

export const Subtitle = styled.p`
    margin: 0;
    padding-top: 14px;
`

export const LocationsWrap = styled.div`
    text-align: left;
    padding: 47.28px 52px;

    @media ${device.tablet} {
        margin-top: 50px;
        height: 100%;
    }
`

export const LocationsList = styled.ul`
    list-style: none;
    margin: 0;
    padding-top: 60px;
    max-height: 300px;
    // overflow: scroll;

    @media ${device.tablet} {
        max-height: 400px;
        height: 400px;
    }
`

export const LocationsLi = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;

    &:last-child {
        padding-bottom: 0;
    }

    @media ${device.tablet} {
        flex-direction: column;
        align-items: baseline;
        padding-bottom: 31px;
    }
`

export const LocationsIcon = styled.div`
    width: 18.5px;
    height: 18.5px;
    border: 2.05556px solid ${colors.cream};
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: '';
        width: 8.91px;
        height: 8.91px;
        border-radius: 50%;
        background: ${colors.cream};
        position: absolute;
    }

    @media ${device.tablet} {
        position: relative;
        top: -29px;
    }
`

export const LocationsTime = styled.p`
    font-weight: normal;
    font-size: 22.025px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.black};
    margin: 0;
    padding-left: 27px;

    @media ${device.tablet} {
        font-size: 15px;
        padding-left: 17px;
    }
`

export const LocationsTitle = styled.p`
    font-weight: normal;
    font-size: 22.025px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.cream};
    margin: 0;

    @media ${device.tablet} {
        font-size: 20px;
    }
`

export const Left = styled.div`
    display: flex;
    align-items: center;
    width: 40%;

    @media ${device.tablet} {
        width: 100%;
        order: 2;
    }
`

export const Right = styled.div`
    display: flex;
    align-items: center;
    width: 45%;

    @media ${device.tablet} {
        width: 100%;
        padding-left: 40px;
        margin-bottom: 10px;
        order: 1;
    }

    @media ${device.mobileL} {
        padding-left: 35px;
    }
`

export const BasketWrap = styled.div`
    padding: 41.28px 30.5px 0;
    text-align: left;

    @media ${device.tablet} {
        padding: 25px 0 0;
    }
`

export const Note = styled.span`
    font-weight: normal;
    font-size: 24.0033px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.cream};
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;

    @media ${device.tablet} {
        font-size: 13px;

        ${props => props.state === 'no-mob' && css`
            display: none;
        `}
    }
`

export const BasketFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1.33352px solid ${colors.grey3};
    padding-top: 30px;
    margin-top: 25px;
`

export const BasketTotalTitle = styled.span`
    font-weight: bold;
    font-size: 20.4907px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.black};
`

export const BasketTotalPrice = styled.span`
    font-weight: bold;
    font-size: 20.4907px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.black};
`

export const Textarea = styled.textarea`
    width: 100%;
    height: 121.75px;
    background: ${colors.white};
    border: 2px solid #1D3745;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;
    padding: 23.5px 28.5px;
    font-size: 20.0028px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.grey};
    margin-top: 20px;

    &::placeholder {
        color: ${colors.grey};
    }

    @media ${device.tablet} {
        margin-top: 12px;
        padding: 12px 14.5px;
        height: 68px;
        font-size: 12px;
    }
`

export const CheckoutBtn = styled.button`
    width: 322px;
    height: 54.56px;
    min-width: 45%;
    border: 2px solid ${colors.primary};
    box-sizing: border-box;
    border-radius: 118.607px;
    font-weight: 600;
    font-size: 20.1632px;
    outline: none;
    cursor: pointer;
    line-height: 20px;
    letter-spacing: 0.13em;
    color: ${colors.white};
    background-color: ${colors.primary};

    @media ${device.tablet} {
        margin-top: 19px;
        height: 42.36px;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
    }
`

export const ContinueShoppingBtn = styled.button`
    width: 322px;
    height: 54.56px;
    min-width: 45%;
    border: 2px solid ${colors.primary};
    box-sizing: border-box;
    border-radius: 118.607px;
    font-weight: 600;
    font-size: 20.1632px;
    outline: none;
    cursor: pointer;
    line-height: 20px;
    letter-spacing: 0.13em;
    color: ${colors.primary};
    background-color: ${colors.white};

    @media ${device.tablet} {
        height: 42.36px;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
    }
`

export const SubmitNote = styled.button`
    width: 141px;
    height: 30px;
    min-width: 45%;
    border: 2px solid ${colors.primary};
    box-sizing: border-box;
    border-radius: 118.607px;
    font-size: 13px;
    line-height: 13px;
    outline: none;
    cursor: pointer;
    line-height: 20px;
    letter-spacing: 0.13em;
    color: ${colors.white};
    background-color: ${colors.primary};
    margin-right: 20px;
    font-weight: bold;
`

export const FooterNote = styled.footer`
    padding-top: 20px;
    display: none;

    @media ${device.tablet} {
        display: block;
    }
`

export const NoteView = styled.footer`
    font-weight: normal;
    font-size: 13px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.grey};
`