import React from 'react'
import { withRouter } from 'react-router-dom';
import { push as Menu } from 'react-burger-menu'
import { useSelector, useDispatch } from 'react-redux'
import clickID from 'components/Reusable/UseClickID'
import img from 'assets'
import {
    token,
    orderStep,
    activePayment,
    paymentID,
    basket,
    price,
    totalPrice,
    calculatedPrice,
    orderID,
    selectedLocationTime,
    paymentMethod
} from 'components/Store/reducer'
import {
    MenuNav,
    Block,
    Mobile,
    Styles,
    CloseIcon,
    Logo,
    Ul,
    Li,
    Ref,
    Email,
    Button
} from './styles'

function DropMenu(props) {
    const email = useSelector(state => state.email)
    const jwt = useSelector(state => state.token)

    const dispatch = useDispatch()

    const close = () => {
        clickID('react-burger-cross-btn')
    }

    const signIn = () => {
        props.history.push('/sign-in')
        clickID('react-burger-cross-btn')
    }

    const joinNow = () => {
        props.history.push('/sign-up')
        clickID('react-burger-cross-btn')
    }

    const icon = () => <img src={img.iconBurger} alt='burger' />

    const logOut = () => {
        dispatch(token(''))
        dispatch(basket(''))
        dispatch(activePayment(false))
        dispatch(paymentID(''))
        dispatch(price(''))
        dispatch(totalPrice(''))
        dispatch(calculatedPrice(''))
        dispatch(orderStep(1))
        dispatch(orderID(0))
        dispatch(selectedLocationTime(''))
        dispatch(paymentMethod(''))
        props.history.push('/')
    }

    const loggedUser = () => {
        return (
            <MenuNav>
                <Ul>
                    <Li>
                        <Ref to='/account' onClick={() => close()}>Account</Ref>
                        <Email>{email}</Email>
                    </Li>
                    <Li>
                        <Ref to='/orders' onClick={() => close()}>Order History</Ref>
                    </Li>
                    <Li>
                        <Ref to='/menu' onClick={() => close()}>Menu</Ref>
                    </Li>
                    <Li>
                        <Ref to='/faq' onClick={() => close()}>FAQ’s</Ref>
                    </Li>
                    <Li>
                        <Ref to='/' black={1} onClick={() => logOut()}>Log Out</Ref>
                    </Li>
                </Ul>
            </MenuNav>
        )
    }

    const unloggedUser = () => {
        return (
            <MenuNav>
                <Button state='sign-in' onClick={() => signIn()}>Sign in</Button>
                <Button state='join-now' onClick={() => joinNow()}>Join now</Button>

                <Ul>
                    {/* <Li>
                        <Ref to='/' onClick={() => close()}>Become a Franchisee</Ref>
                    </Li>

                    <Li>
                        <Ref to='/' onClick={() => close()}>Business Account</Ref>
                    </Li> */}

                    <Li>
                        <Ref to='/about' onClick={() => close()}>About</Ref>
                    </Li>
                </Ul>
            </MenuNav>
        )
    }

    const renderState = () => {
        if (jwt) {
            return loggedUser()
        }
        return unloggedUser()
    }

    return (
        <Mobile>
            <Menu
                styles={Styles}
                pageWrapId={'page-wrap'}
                customBurgerIcon={icon()}
                right
            >

                <Block>
                    <CloseIcon src={img.iconClose} alt='close' onClick={() => close()} />
                    <Logo src={img.bgMobLogo} alt='logo' />
                    {renderState()}
                </Block>

            </Menu>
        </Mobile>
    )
}

export default withRouter(DropMenu)