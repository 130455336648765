import { React } from 'react';
import { Row, Col } from 'react-bootstrap';
import beans from 'assets/images/beans.svg';
import Cta from 'components/Reusable/Cta';
import { Link } from "react-router-dom";
import useWindowSize from 'components/Reusable/WindowSize'
import clickID from 'components/Reusable/UseClickID'
import {
    Wrapper,
    HeroContainer,
    BG,
    MobileWrap
} from './styles'


const NotifiedHero = () => {
    const size = useWindowSize()
    return (
        <section className=''>
            <Wrapper>
                <MobileWrap>
                    <Row>
                        <Col md="6" >
                            <HeroContainer>
                                <h1>Thank you for registering your interest</h1>
                                <p>
                                    We will be in touch when our van is nearby.<br />
                                    In the meantime why not have a look at our menu.
                                </p>
                                <Link to="/menu" onClick={() => clickID('top')}>
                                    <Cta type="primary" tag="button" textcontent="View Menu" />
                                </Link>
                            </HeroContainer>
                        </Col>

                        {size.width > 800 && (
                            <Col md="6" className="col-sm-6 order-1">
                                <div className="img-container">
                                    <BG src={beans} alt="Coffee Bean" />
                                </div>
                            </Col>
                        )}
                    </Row>
                </MobileWrap>
            </Wrapper>
        </section>
    );
}

export default NotifiedHero;
