import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from 'components/pages/Home';
import SignIn from 'components/pages/SignIn';
import SignUp from 'components/pages/SignUp';
import Faq from 'components/pages/Faq';
import Menu from 'components/pages/Menu';
import Contact from 'components/pages/Contact';
import Vans from 'components/pages/Vans';
import VanList from 'components/pages/VanList';
import AccountPage from 'components/pages/Account';
import About from 'components/pages/About';
import Privacy from 'components/pages/Privacy';
import Terms from 'components/pages/Terms';
import Cookie from 'components/pages/Cookie';
import ThankYou from 'components/pages/ThankYou';
import Thanks from 'components/pages/Thanks';
import Thank from 'components/pages/Thank';
import Orders from 'components/pages/Orders';
import Order from 'components/pages/Order';
import ForgottenPass from 'components/pages/ForgottenPass';
import NewPass from 'components/pages/NewPass';
import Checkout from 'components/pages/Checkout';
import OrderCompleted from 'components/pages/OrderCompleted';
import OrderCompletedStripe from 'components/pages/OrderCompletedStripe';
import InviteFriends from 'components/pages/InviteFriends';
import DrinkModal from 'components/Reusable/DrinkModal';
import Wrapper from 'components/Layout/wrapper';
// import PWA from 'components/Sections/PWA'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PaymentStatus from 'components/Layout/payment-status'
import GetApi from 'api'
import WelcomeBack from 'components/Layout/welcome-back.js'
import Toastify from 'components/Reusable/Toastify'
import GetMeta from 'components/Reusable/GetMeta'

function App() {
	return (
		<Router>
			<GetMeta />
			<Wrapper>
				<Toastify />
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="/sign-in" component={SignIn} />
					<Route path="/sign-up" component={SignUp} />
					<Route path="/faq" component={Faq} />
					<Route path="/contact" component={Contact} />
					<Route path="/menu" component={Menu} />
					<Route path="/vans" component={Vans} />
					<Route path="/van-list" component={VanList} />
					<Route path="/account" component={AccountPage} />
					<Route path="/about" component={About} />
					<Route path="/privacy" component={Privacy} />
					<Route path="/terms" component={Terms} />
					<Route path="/cookie" component={Cookie} />
					<Route path="/thank-you" component={ThankYou} />
					<Route path="/thankyou" component={Thanks} />
					<Route path="/thank" component={Thank} />
					<Route path="/orders" component={Orders} />
					<Route path="/order/:id" component={Order} />
					<Route path="/forgotten-pass" component={ForgottenPass} />
					<Route path="/new-pass" component={NewPass} />
					<Route path="/checkout" component={Checkout} />
					<Route path="/invite-friends" component={InviteFriends} />
					<Route path="/order-completed" component={OrderCompleted} />
					<Route path="/order-completed-stripe" component={OrderCompletedStripe} />
				</Switch>
				<DrinkModal />
				<PaymentStatus />
				<WelcomeBack />
				<GetApi />
				{/* <PWA /> */}
			</Wrapper>
		</Router>
	)
}

export default App