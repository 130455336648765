import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useOptions from 'components/Reusable/UseOptions'
import {
    Ul,
    Li
} from '../styles'

function RenderOptions(props) {
    const { data } = props
    const path = props.location.pathname.slice(1)
    const options = useOptions()
    const additionalOptions = useSelector(state => state.additionalOptions)

    const additionalArr = data && data.additionalOptions

    if (path === 'checkout' && data) {
        const list = data.options && additionalArr && data.options
            .filter(x => additionalArr.find(id => x.id === +id))
            .map(x =>
                <Li key={x.id}>{x.name}</Li>
            )

        return <Ul>{list}</Ul>
    }

    const list = options && additionalOptions && options
        .filter(x => additionalOptions.find(id => x.id === +id))
        .map(x =>
            <Li key={x.id}>{x.name}</Li>
        )

    return <Ul>{list}</Ul>
}

export default withRouter(RenderOptions)