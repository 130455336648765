import logo from './logo/logo.svg'
import iconMapDot from './icons/map-dot.svg'
import iconMapLogo from './icons/map-logo.svg'
import iconCoffee from './icons/coffee.svg'
import iconSnowflake from './icons/snowflake.svg'
import iconCroissant from './icons/croissant.svg'
import iconTea from './icons/tea.svg'
import iconClose from './icons/close.svg'
import iconClosePwa from './icons/close-pwa.svg'
import iconCategoriesArrow from './icons/categories-arrow.svg'
import iconSecure from './icons/secure.svg'
import iconBurger from './icons/burger.svg'
import iconDropArrow from './icons/drop-arrow.svg'
import iconFacebook from './icons/facebook.svg'
import iconInstagram from './icons/insta.svg'
import iconLinkedin from './icons/LinkedIn.svg'
import iconTwitter from './icons/twitter.svg'
import iconGoogle from './icons/google.svg'
import iconEmail from './icons/email.svg'
import iconTrash from './icons/trash.svg'
import iconPaymentGoogle from './payment-methods/google.svg'
import iconPaymentMaster from './payment-methods/master.svg'
import iconPaymentApple from './payment-methods/apple.svg'
import iconPaymentPaypal from './payment-methods/paypal.svg'
import iconPaymentVisa from './payment-methods/visa.svg'
import arrowLeft from './icons/slider-arrow-left.svg'
import arrowRight from './icons/slider-arrow-right.svg'
import bgLeft from './bg/bg-left.svg'
import bgRight from './bg/bg-right.svg'
import point from './images/point.svg'
import pointW from './images/point-w.svg'
import frappe from './images/frappe.png'
import bgMobLogo from './bg/mob-logo.svg'
import bgBean1 from './images/bean-1.png'
import bgBean2 from './images/bean-2.png'
import bgBean3 from './images/bean-3.png'
import bgBean4 from './images/bean-4.png'
import bgBeans2 from './bg/beans2.svg'
import bgBeans from './images/beans.svg'
import bgVan from './bg/van.png'
import bgVanCard from './bg/van-card.png'
import bgLocation from './bg/location.png'

const img = {
    logo: logo,
    iconMapDot: iconMapDot,
    iconMapLogo: iconMapLogo,
    iconCoffee: iconCoffee,
    iconSnowflake: iconSnowflake,
    iconCroissant: iconCroissant,
    iconTea: iconTea,
    iconClosePwa: iconClosePwa,
    iconClose: iconClose,
    iconCategoriesArrow: iconCategoriesArrow,
    iconSecure: iconSecure,
    iconBurger: iconBurger,
    iconDropArrow: iconDropArrow,
    iconFacebook: iconFacebook,
    iconInstagram: iconInstagram,
    iconLinkedin: iconLinkedin,
    iconGoogle: iconGoogle,
    iconTwitter: iconTwitter,
    iconEmail: iconEmail,
    iconTrash: iconTrash,
    iconPaymentGoogle: iconPaymentGoogle,
    iconPaymentMaster: iconPaymentMaster,
    iconPaymentApple: iconPaymentApple,
    iconPaymentPaypal: iconPaymentPaypal,
    iconPaymentVisa: iconPaymentVisa,
    arrowLeft: arrowLeft,
    arrowRight: arrowRight,
    bgRight: bgRight,
    bgLeft: bgLeft,
    point: point,
    pointW: pointW,
    frappe: frappe,
    bgMobLogo: bgMobLogo,
    bgBeans: bgBeans,
    bgBean1: bgBean1,
    bgBean2: bgBean2,
    bgBean3: bgBean3,
    bgBean4: bgBean4,
    bgBeans2: bgBeans2,
    bgVan: bgVan,
    bgVanCard: bgVanCard,
    bgLocation: bgLocation
}

export default img