import React from 'react'
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux'
import useOptions from 'components/Reusable/UseOptions'
import coffee from 'assets/placeholders/coffee.png'
import API from 'components/Store/api'
import Cta from '../Cta'
import clickID from 'components/Reusable/UseClickID'
import {
    additionalOptions,
    additionalPrice,
    staticAdditionalPrice,
    drinkmodal,
    orderStep
} from 'components/Store/reducer'
import {
    Label,
    Wrap,
    CheckboxInput,
    Checkmark,
    CheckLabel,
    CheckboxPrice,
    Group,
    Hidden,
    Article,
    HeaderBg,
    Footer,
    BackButton,
} from './styles'

function Step2(props) {
    const options = useOptions()
    const dispatch = useDispatch()
    const productName = useSelector(state => state.product.name)
    const productDescription = useSelector(state => state.product.description)
    const productImage = useSelector(state => state.product.image_path)

    const handleClose = () => {
        dispatch(drinkmodal(false))
        dispatch(orderStep(1))
        dispatch(additionalPrice(0))
        dispatch(staticAdditionalPrice(0))
        setTimeout(() => props.history.push('/menu'), 20)
    }

    const nextStep = () => {
        clickID('milk-options')
        dispatch(orderStep(3))
    }

    const MilkOptions = () => (
        <Formik
            initialValues={{ checked: [] }}
            onSubmit={(values, { setSubmitting }) => {
                const addPrice = options && options
                    .filter(x => values.checked.find(id => x.id === +id))
                    .map(x => x.add_price)
                    .reduce((a, b) => +a + +b, 0)
                    .toFixed(2)

                setTimeout(() => {
                    dispatch(additionalOptions(values.checked))
                    setTimeout(() => {
                        if (values.checked.length) {
                            dispatch(additionalPrice(addPrice))
                            dispatch(staticAdditionalPrice(addPrice))
                        }
                    }, 200)
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({ isSubmitting, values }) => {

                const renderAdditionalOptions = () => {
                    const list = options && options.map(item => {
                        const id = item.id.toString()
                        return (
                            <Label key={id} htmlFor={id}>
                                <Wrap>
                                    <CheckboxInput
                                        type='checkbox'
                                        name='checked'
                                        value={id}
                                        id={id}
                                        price={item.add_price}
                                    />
                                    <Checkmark className='checkmark' />
                                    <CheckLabel>{item.name}</CheckLabel>
                                </Wrap>
                                <CheckboxPrice>(+£{item.add_price})</CheckboxPrice>
                            </Label>
                        )
                    })
                    return list
                }

                return (
                    <Form>
                        <Group className="extra-options" role="group" aria-labelledby="checkbox-group">
                            {renderAdditionalOptions()}
                        </Group>
                        <Hidden
                            type="submit"
                            id="milk-options"
                            className="ctn-btn"
                            disabled={isSubmitting}
                        >Submit</Hidden>
                    </Form>
                )
            }}
        </Formik>
    );

    return (
        <>
            <Article>
                <div className="modal-header">
                    <HeaderBg>
                        <p className="modal-title">{productName}</p>
                        <p className="modal-desc">{productDescription}</p>
                    </HeaderBg>
                    <img src={productImage ? `${API}/menu_items/${productImage}` : coffee} alt={productImage} />
                </div>
                <div className="modal-main">
                    {MilkOptions()}
                </div>
            </Article>
            <Footer>
                <div className="modal-footer">
                    <BackButton onClick={() => handleClose()}>Close</BackButton>
                    <div onClick={() => nextStep()} className='ctnbtn'>
                        <Cta
                            type="primary"
                            tag="button"
                            textcontent="Add to Basket"
                        />
                    </div>
                </div>
            </Footer>
        </>
    )
}

export default withRouter(Step2)