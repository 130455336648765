import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trigger } from "components/Store/reducer";
import { getOrderDetails } from "components/Store/thunk";

function GetOrderDetails() {
  const alert = useSelector((state) => state.trigger);
  const dispatch = useDispatch();

  const ALLOWED_ALERTS_TYPES = ["get-order-details"];

  useEffect(() => {
    if (ALLOWED_ALERTS_TYPES.includes(alert)) {
      dispatch(trigger(""));
      dispatch(getOrderDetails());
    }
  }, [alert]);

  return null;
}

export default GetOrderDetails;
