import React, { useEffect } from 'react'
import { quantity, additionalPrice } from 'components/Store/reducer'
import { useDispatch, useSelector } from 'react-redux'
import {
    QuantityRow,
    QuantityDecrement,
    QuantityIncrement,
    QuantityValue
} from './styles'

function Quantity() {
    const dispatch = useDispatch()
    const q = useSelector(state => state.quantity)
    const staticPrice = useSelector(state => state.staticAdditionalPrice)

    useEffect(() => {
        const addPrice = +staticPrice * q
        dispatch(additionalPrice( (addPrice).toFixed(2) ))
    })

    const handleIncrement = () => {
        dispatch(quantity(q + 1))
    }
    const handleDecrement = () => {
        if (q > 1) {
            dispatch(quantity(q - 1))
        }
    }

    return (
        <QuantityRow>
            <QuantityDecrement onClick={() => handleDecrement()}>-</QuantityDecrement>
            <QuantityValue>{q}</QuantityValue>
            <QuantityIncrement onClick={() => handleIncrement()}>+</QuantityIncrement>
        </QuantityRow>
    )
}

export default Quantity