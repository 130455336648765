import React, { useState } from 'react'
import Basket from 'components/Sections/Basket'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { note, calculatedPrice } from 'components/Store/reducer'
import clickID from 'components/Reusable/UseClickID'
import {
    BasketWrap,
    Title,
    Note,
    BasketFooter,
    BasketTotalTitle,
    BasketTotalPrice,
    Textarea,
    Hidden,
    SubmitNote,
    FooterNote,
    NoteView
} from './styles'

function BasketState() {
    const [toggle, setToggle] = useState(false)

    const dispatch = useDispatch()
    const price = useSelector(state => state.price)
    const additionalPrice = useSelector(state => state.additionalPrice)
    const productNote = useSelector(state => state.note)

    const {
        register,
        handleSubmit,
    } = useForm();
    const onSubmit = (data) => dispatch(note(data.note))

    const renderPrice = () => {
        const val = Number(price) + Number(additionalPrice)
        const fixedPrice = val.toFixed(2)
        dispatch(calculatedPrice(fixedPrice))
        
        return `£ ${fixedPrice}`
    }

    const handleSubmitNote = () => {
        clickID('note-submit')
        setToggle(false)
    }

    const renderNote = () => {
        return productNote && <NoteView>Note: {productNote}</NoteView>
    }

    const renderToggleBtn = () => {
        if (productNote) {
            return <Note className='note' onClick={() => dispatch(note(''))}>Remove</Note>
        }
        return <Note className='note' onClick={() => setToggle(true)}>Add a note</Note>
    }

    const renderNoteState = () => {
        if (toggle) {
            return (
                <>
                    <Note state='no-mob' className='note' onClick={() => setToggle(false)}>Cancel</Note>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Textarea {...register('note')} placeholder='Type here' />
                        <Hidden type='submit' id='note-submit' />
                    </form>
                    <FooterNote>
                        <SubmitNote className='note' type='button' onClick={() => handleSubmitNote()}>Submit</SubmitNote>
                        <Note className='note' onClick={() => setToggle(false)}>Cancel</Note>
                    </FooterNote>
                </>
            )
        }
        return (
            <>
                {renderNote()}
                {renderToggleBtn()}
                <Hidden type='button' id='note-submit' />
            </>
        )
    }

    return (
        <BasketWrap>
            <Title>Basket</Title>
            <Basket modal />
            {renderNoteState()}

            <BasketFooter>
                <BasketTotalTitle>Total</BasketTotalTitle>
                <BasketTotalPrice>{renderPrice()}</BasketTotalPrice>
            </BasketFooter>
        </BasketWrap>
    )
}

export default BasketState