import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import NotifyForm from 'components/Reusable/NotifyForm'
import { drinkmodal } from 'components/Store/reducer';
import { useDispatch } from 'react-redux';
import Cta from '../Cta'
import {
    NotifyWrap,
    Footer
} from './styles'

function UnauthorizedState(props) {
    const [showNotify, setShowNotify] = useState(false)

    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(drinkmodal(false))
        props.history.push('/menu')
    }

    const renderFooter = () => {
        if (showNotify) {
            return (
                <NotifyWrap>
                    <NotifyForm modal='1' />
                </NotifyWrap>
            )
        }
        return (
            <div className="modal-footer">
                <div onClick={() => handleClose()} >
                    <Cta type="secondary" tag="button" textcontent="Back" />
                </div>
                <div onClick={() => setShowNotify(true)} className="ctnbtn">
                    <Cta
                        type="primary"
                        tag="button"
                        textcontent="Notify Me"
                    />
                </div>
            </div>
        )
    }

    return (
        <Footer>
            <p className="know-more">Want to know when your van is near you?</p>
            {renderFooter()}
        </Footer>
    )
}

export default withRouter(UnauthorizedState)