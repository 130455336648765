import React from "react";
import ProductData from "./product-data";
import GetUser from "./use-get-user";
import VansController from "./all-vans";
import GetReceipt from "./get-receipt";
import OrderAgain from "./order-again";
import GetOrderDetails from "./get-order-details";

function GetApi() {
  return (
    <>
      <ProductData />
      <GetUser />
      <VansController />
      <GetReceipt />
      <OrderAgain />
      <GetOrderDetails />
    </>
  );
}

export default GetApi;
