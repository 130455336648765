import React, { useState } from 'react'
import { totalPrice, currentQuantity } from 'components/Store/reducer'
import clickID from 'components/Reusable/UseClickID'
import { useDispatch } from 'react-redux'
import {
    QuantityRow,
    QuantityDecrement,
    QuantityIncrement,
    QuantityValue
} from './styles'

function BasketQty(props) {
    const { data } = props

    const [qty, setQty] = useState(data && data.quantity)
    const dispatch = useDispatch()

    const HandleIncrement = () => {
        setQty(qty + 1)
        dispatch(totalPrice(qty + 1))
        dispatch(currentQuantity(qty + 1))
        clickID(`increment-${data && data.id}`)
        
        setTimeout(() => {
            clickID(`replace-${data && data.id}`)
        }, 300)
    }
    const HandleDecrement = () => {
        if (qty > 1) {
            setQty(qty - 1)
            dispatch(totalPrice(qty - 1))
            dispatch(currentQuantity(qty - 1))
            clickID(`decrement-${data && data.id}`)

            setTimeout(() => {
                clickID(`replace-${data && data.id}`)
            }, 300)
        }
    }

    const renderState = () => {
        if (data) {
            return (
                <QuantityRow>
                    <QuantityDecrement id={`decrement-${data && data.id}`} onClick={() => HandleDecrement()}>-</QuantityDecrement>
                    <QuantityValue>{qty}</QuantityValue>
                    <QuantityIncrement id={`increment-${data && data.id}`} onClick={() => HandleIncrement()}>+</QuantityIncrement>
                </QuantityRow>
            )
        }
        return null
    }

    return renderState()
}

export default React.memo(BasketQty)