import React from 'react'
import { Link } from 'react-scroll'
import { useSelector } from 'react-redux'

function ScrollTo() {
    const menus = useSelector(state => state.notify)

    const renderLinks = () => {
        const list = menus.menus && menus.menus.map(item =>
            <Link
                key={item.id}
                to={`c${item.id}`}
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
                id={`category-${item.id}`}
            />
        )
        return list
    }

    return (
        <>
            {renderLinks()}
            <Link
                to='scroll-top'
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
                id='top'
            />
            <Link
                to='payment-method'
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
                id='scroll-to-payment-method'
            />
            <Link
                to='menu-nav'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                id='nav'
            />
        </>
    )
}

export default ScrollTo