import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
    BasketTitle,
} from '../styles'

function RenderTitle(props) {
    const { data } = props
    const path = props.location.pathname.slice(1)

    const productName = useSelector(state => state.product.name)

    // NWE product

    if (path === 'checkout' && data) {
        return <BasketTitle>{data.product?.name}</BasketTitle>
    }
    return <BasketTitle>{productName}</BasketTitle>
}

export default withRouter(RenderTitle)