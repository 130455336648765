import React from 'react'

function Step4() {

    return (
        <div className="modal-main">
            step 4
        </div>
    )
}

export default Step4