import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import coffee from 'assets/placeholders/coffee.png'
import API from 'components/Store/api'
import UnauthorizedState from './unauthorized'
import Cta from '../Cta'
import useOptions from 'components/Reusable/UseOptions'
import {
    drinkmodal,
    orderStep,
    additionalPrice,
    staticAdditionalPrice,
    additionalOptions,
} from 'components/Store/reducer'
import {
    Article,
    HeaderBg,
    Footer,
    BackButton
} from './styles'

function Step1(props) {
    const productData = useSelector(state => state.product)
    const JWT = useSelector(state => state.token)
    const options = useOptions()

    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(drinkmodal(false))
        dispatch(orderStep(1))
        dispatch(additionalPrice(0))
        dispatch(staticAdditionalPrice(0))
        dispatch(additionalOptions([]))
        props.history.push('/menu')
    }

    const next = () => {
        options.length ? dispatch(orderStep(2)) : dispatch(orderStep(3))
    }

    const renderNutritional = () => {
        if (productData.nutritional_information) {
            const list = productData.nutritional_information.map(item =>
                <li key={Math.random()}>
                    {item.name}
                    <span>{item.value}</span>
                </li>
            )
            return <ul>{list}</ul>
        }

        return <div />
    }

    const renderFooter = () => {
        return JWT ? (
            <Footer>
                <div className="modal-footer">
                    <BackButton onClick={handleClose}>Back</BackButton>
                    <div onClick={() => next()} className='ctnbtn'>
                        <Cta
                            type="primary"
                            tag="button"
                            textcontent="Order now"
                        />
                    </div>
                </div>
            </Footer>
        ) : <UnauthorizedState />
    }

    return (
        <>
            <Article>
                <div className="modal-header">
                    <HeaderBg>
                        <p className="modal-title">{productData.name}</p>
                        <p className="modal-desc">{productData.description}</p>
                    </HeaderBg>
                    <img src={productData.image_path ? `${API}/menu_items/${productData.image_path}` : coffee} alt={productData.name} />
                </div>

                <div className="modal-main">
                    <div className="modal-ingredients">
                        <p>Ingredients and Allergens</p>
                        <p>{productData.ingredients_and_allergens}</p>
                    </div>
                    <div className="modal-nutrition">
                        {renderNutritional()}
                    </div>
                </div>
            </Article>
            {renderFooter()}
        </>
    )
}

export default withRouter(Step1)