import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trigger } from 'components/Store/reducer'
import { loadReceipt } from 'components/Store/thunk'

function GetReceipt() {
    const alert = useSelector(state => state.trigger)
	const dispatch = useDispatch()

	const ALLOWED_ALERTS_TYPES = [
		'get-receipt'
    ]

	useEffect(() => {
		if (ALLOWED_ALERTS_TYPES.includes(alert)) {
            dispatch(trigger(''))
			dispatch(loadReceipt())
		}
	}, [alert])

	return null
}

export default GetReceipt