import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadAllVans } from 'components/Store/thunk'

function VansController() {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(loadAllVans())
	},[])

	return null
}

export default VansController