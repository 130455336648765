import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import img from 'assets'
import API from '../../Store/api'
import axios from 'axios'
import {
    MapCanvas,
    styles,
    EmptyCanvas
} from './styles'

function VanMap() {
    const menuList = useSelector(state => state.notify)
    const [vanStatus, setVanStatus] = useState('');
    const [mapLoaded, setMapLoaded] = useState(false)
    let  map = null; 
    let myInterval = null; 
    const INTERVAL_TIMEOUT = 60000 

    useEffect( async ()=>{ 
        await axios({
            method: 'GET',
            url: `${API}/api/van/details/1`
        }).then((res)=>{
            setVanStatus(res.data.data.status)
            console.log('res.data.data.status  = ', res.data.data.status)
        }).catch((err)=>{
            console.log("ERROR HERE ---- ",err)
        });
        return () => {
            clearInterval(myInterval)
        };
    }, [])

    
    const locations = () => {
        const local = menuList && menuList.routes.map(item => (
            { location: new window.google.maps.LatLng(+item.lat, +item.long) }
        ))
        return local
    }

    const load_map = async  () => {

        let htmlMapCanvas = document.getElementById('map-canvas')
  
        if(htmlMapCanvas === null || mapLoaded) return false  
        setMapLoaded(true)

        const locs = locations(); 
        var pointA = locs[0].location
        var pointB = locs.slice(-1)[0] 

        let myOptions = {
            zoom: 9,
            center: pointA,
            styles: styles,
            disableDefaultUI: true,
            draggable: true
        }
        map = new window.google.maps.Map(htmlMapCanvas, myOptions)  
    
        let directionsService = new window.google.maps.DirectionsService(); 
        let directionsDisplay = new window.google.maps.DirectionsRenderer({
            map: map,
            polylineOptions: {
                strokeColor: '#d09e6f',
                strokeWeight: 5
            },
            markerOptions: {
                icon: {
                    url: img.iconMapDot,
                    anchor: { x: 12, y: 12 },
                },
                zIndex: 1
            }
        }); 

        const vanGpsData =  (await axios({ method: 'GET',  url: `${API}/api/location/1` })  ).data.data.location[0] 
        const vanPos =  {lat: parseFloat(vanGpsData?.GPSLatitude), lng: parseFloat(vanGpsData?.GPSLongitude)  };  
        
        let vanMarker = new window.google.maps.Marker({
            position: vanPos, 
            map: map,
            icon: { url: img.iconMapLogo,  scaledSize: new window.google.maps.Size(30, 30) },  
            zIndex: 2, 
        });
 
        myInterval = setInterval(async () => {
            const vanGpsIntervalData =  (await axios({ method: 'GET',  url: `${API}/api/location/1` })) .data.data.location[0]  
            vanMarker.setPosition( new window.google.maps.LatLng( parseFloat(vanGpsIntervalData?.GPSLatitude),parseFloat(vanGpsIntervalData ?.GPSLongitude)  ) );
        }, INTERVAL_TIMEOUT);
 
        calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB);
    }
 

    function calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB) {
        const local = locations() 
        directionsService.route({
            origin: pointA,
            destination: pointB,
            waypoints: local,
            avoidTolls: true,
            avoidHighways: false,
            travelMode: window.google.maps.TravelMode.DRIVING
        }, function (response, status) {
            if (status === window.google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
            } else {
                window.alert('Directions request failed due to ' + status);
            }
        });
    }
   
 

    return (
        <>
            <MapCanvas id='map-canvas' className={vanStatus ? '' : 'hidden'}/>
            <EmptyCanvas className={vanStatus ? 'hidden' : ''}>
                <h3>
                    Sorry coffee lover! <br/>
                    Our van is currently unavailable right now. Please check back soon.
                </h3>
            </EmptyCanvas>
            <img style={{opacity: "0", height: 0, width: 0}} src={img.iconMapLogo}   onLoad={load_map} />
        </>
    )
}

export default VanMap