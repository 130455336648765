const initialState = {
  token: "",
  email: "",
  basket: [],
  counter: "",
  signup: "step-1",
  searchfaq: "",
  contact: "",
  drinkmodal: "",
  product: "",
  notify: "",
  error: "",
  note: "",
  quantity: 1,
  price: "",
  totalPrice: 0,
  additionalPrice: 0,
  staticAdditionalPrice: 0,
  calculatedPrice: 0,
  additionalOptions: [],
  orderStep: 1,
  orderID: "",
  orderStatus: "",
  orderError: "",
  orderDetails: "",
  basketPrices: [],
  productSum: 0,
  fixedPrice: "0",
  currentQuantity: 1,
  selectedLocationID: 0,
  selectedLocationTime: "",
  paymentMethod: "",
  paymentID: "",
  activePayment: false,
  filteredMenus: [],
  trigger: "",
  filteredFaq: [],
  userData: "",
  allVans: [],
  isFreeCoffee: false,
  receipt: null,
  orderAgain: 0,
  welcomeBack: false,
  buildVersion: "",
  errorLocation: false,
};

export default initialState;
