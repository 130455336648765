import { React, useState, useEffect } from "react"
import { withRouter } from 'react-router-dom'
import Cta from "components/Reusable/Cta"
import { Formik } from 'formik'
import { drinkmodal, error, token } from 'components/Store/reducer'
import { useSelector, useDispatch } from 'react-redux'
import axios from "axios";
import API from 'components/Store/api'
import clickID from 'components/Reusable/UseClickID'
import validate from './validate'
import initialValues from './initialValues'
import {
    Input,
    Header,
    Row,
    Col,
    Footer,
    CheckboxLabel,
    CheckboxInput,
    Checkmark,
    CheckLabel,
    Error,
    Info,
    InfoLink,
    BtnWrap,
    LogInButton
} from './styles'

const NotifyForm = props => {
    const { modal } = props
    const path = props.location.pathname.slice(1)
    const [lat, setLat] = useState()
    const [long, setLong] = useState()
    const [loader, setLoader] = useState(false)
    const [errorLocation, setErrorLocation] = useState(false)
    const [checkEmailMsg, setCheckEmailMsg] = useState('')

    const errorMsg = useSelector(state => state.error)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(error(''))
    }, [dispatch])

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition)
        }
    })

    useEffect(() => {
        let input = document.getElementById('email')
        if (input) {
            input.onblur = inputBlur
            function inputBlur() {
                checkEmail(input.value)
            }
        }
    },[])

    function showPosition(position) {
        setLat(position.coords.latitude)
        setLong(position.coords.longitude)
    }

    const checkEmail = email => {
        axios.post(`${API}/api/check_email`, { email: email })
            .then(res => {
                setCheckEmailMsg(res.data.data.email)
            })
            .catch(err => {
                setCheckEmailMsg(err?.response?.data?.message)
            })
    }

    const handleSignIn = () => {
        clickID('close')
        props.history.push('/sign-in')
    }

    const NotifyForm = () => (
        <Formik
            initialValues={initialValues}
            validate={values => validate(values)}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {

                    const config = {
                        headers: { 'Access-Control-Allow-Origin': '*' },
                        email: values.email,
                        name: values.name,
                        password: values.password,
                        phone_number: values.phone,
                        lat: lat,
                        long: long,
                        sms_notifications: values.checkbox ? 1 : 0
                    }

                    axios.post(`${API}/api/notifications`, config)
                        .then(res => {
                            dispatch(drinkmodal(false))
                            dispatch(token(res.data?.data?.remember_token))
                            clickID('top')
                            dispatch(error(''))
                            setLoader(false)
                            props.history.push('/thank-you')
                        })
                        .catch(err => {
                            setErrorLocation(err.response.status === 409)
                            // dispatch(error(err.response.data?.data?.email[0]))
                            setLoader(false)
                        })

                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => {

                return (
                    <form onSubmit={handleSubmit}>
                        <Header state={props.modal}>
                            <Row state={modal}>
                                <Col>
                                    <Input
                                        styles={modal}
                                        type="name"
                                        name="name"
                                        placeholder="Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        validation={errors.name && touched.name && errors.name}
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        styles={modal}
                                        type="phone"
                                        name="phone"
                                        placeholder="Mobile number"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                        validation={errors.phone && touched.phone && errors.phone}
                                    />
                                </Col>
                            </Row>
                            <Row state={modal}>
                                <Col>
                                    <Input
                                        styles={modal}
                                        type="email"
                                        name="email"
                                        id='email'
                                        placeholder="Email address"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        validation={errors.email && touched.email && errors.email}
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        styles={modal}
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        validation={errors.password && touched.password && errors.password}
                                    />
                                </Col>
                            </Row>
                        </Header>

                        {checkEmailMsg === 'Email exists' && <Info>This email address has already signed up to our service. <InfoLink to='/sign-in'>Please log in here</InfoLink></Info>}

                        <Footer>
                            <CheckboxLabel htmlFor='sms'>
                                <CheckboxInput
                                    id='sms'
                                    type='checkbox'
                                    name='sms_notifications'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.sms_notifications}
                                    validation={errors.sms_notifications && touched.sms_notifications && errors.sms_notifications}
                                />
                                <Checkmark className='checkmark' />
                                <CheckLabel>I agree to be notified by SMS.</CheckLabel>
                            </CheckboxLabel>
                        </Footer>

                        {errorMsg && path && <Error>{errorMsg}</Error>}

                        {errorLocation && (
                            <Error state='left'>Please enable location services so we can better personalise your Vanoffee experience</Error>
                        )}

                        <BtnWrap>
                            <Cta
                                type='submit primary'
                                textcontent='Register'
                                tag='button'
                                loader={loader}
                                disabled={isSubmitting}
                            />

                            <LogInButton onClick={() => handleSignIn()}>Sign in</LogInButton>

                        </BtnWrap>

                    </form>
                )
            }}
        </Formik>
    )

    return NotifyForm()
}

export default withRouter(NotifyForm);
