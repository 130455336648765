import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import img from 'assets'

function Toastify() {

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(() => {}, () => {
            notify()
        })
    })

    const notify = () => toast(
        <>
            <img src={img.bgLocation} alt='alert' />
            Please enable location services
        </>
    )

    return <ToastContainer progressClassName="toastProgress" />
}

export default Toastify