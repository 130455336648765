import styled, { css } from 'styled-components'
import colors from 'components/Theme/colors'
import { device } from 'components/Theme/device'

export const BasketRow = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 21px;
`

export const BasketCol = styled.div`

    & p {
        margin: 0;
    } 

    ${props => props.width && css`
        width: ${props.width};
    `}

    ${props => props.modal && css`
        font-weight: normal;
        font-size: 26.6704px;
        line-height: 104%;
        letter-spacing: 0.13em;
    `}

    @media ${device.mobileL} {
        font-size: 15.2308px;

        ${props => props.width === '70%' && css`
            padding-left: 10px;
        `}
    }
`

export const BasketTitle = styled.h4`
    font-weight: normal;
    font-size: 20.4907px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.black};
    margin: 0;

    @media ${device.mobileL} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const QuantityRow = styled.div`
    display: flex;
    align-items: center;
`

export const QuantityDecrement = styled.span`
    font-weight: normal;
    font-size: 23.2703px;
    letter-spacing: -0.02em;
    color: ${colors.cream};
    cursor: pointer;
`

export const QuantityIncrement = styled.span`
    font-weight: normal;
    font-size: 23.2703px;
    letter-spacing: -0.02em;
    color: ${colors.cream};
    cursor: pointer;
`

export const QuantityValue = styled.h4`
    font-weight: normal;
    font-size: 23.2703px;
    color: ${colors.black};
    margin: 0 8px;
`

export const BasketFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const BasketTotalTitle = styled.span`
    font-weight: bold;
    font-size: 20.4907px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.black};
`

export const BasketTotalPrice = styled.span`
    font-weight: bold;
    font-size: 20.4907px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.black};
`

export const Ul = styled.ul`
    list-style: none;
`

export const Li = styled.li`
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.13em;
    color: ${colors.grey};
    font-weight: normal;
    font-size: 17.0755px;

    @media ${device.mobileL} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`