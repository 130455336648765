import styled, { keyframes } from 'styled-components'
import colors from 'components/Theme/colors.js'

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.span`
    display: block;
    margin-left: 10px;

    &:after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 2px solid ${colors.white};
        border-color: ${colors.white} transparent ${colors.white} transparent;
        animation: ${rotate} 1.2s linear infinite;
    }
`;