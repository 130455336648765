import React, { useState, useEffect } from 'react'
import { filteredMenus } from 'components/Store/reducer'
import { useSelector, useDispatch } from 'react-redux'
import useWindowSize from 'components/Reusable/WindowSize'
import clickID from 'components/Reusable/UseClickID'
import { SearchField, MobileWrap } from './styles'

function Search(props) {
    const { hidden, state } = props
    const [search, setSearch] = useState('');
    const size = useWindowSize()
    const notify = useSelector(state => state.notify)
    const dispatch = useDispatch()

    const filtered = notify && notify.menus.map(category => {
        return { category: category, filter: category.items.filter(item => item.name.toLowerCase().includes(search.toLowerCase())) }
    })

    useEffect(() => {
        dispatch(filteredMenus(filtered))
    },[filtered, dispatch])

    const handleSearch = e => {
        setSearch(e.target.value)
        clickID('nav') 
    }

    return (
        <MobileWrap>
            <SearchField
                type='text'
                placeholder={size.width > 440 ? 'Search for your favourite beverage' : 'Search'}
                onChange={e => handleSearch(e)}
                hidden={hidden}
                state={state}
            />
        </MobileWrap>
    )
}

export default Search