import useWindowSize from 'components/Reusable/WindowSize'

const ModalStyles = () => {
    const size = useWindowSize()

    const modalStyles = {
        content : {
            width: `${size.width < 700 ? '100%' : '688px'}`,
            height: `${size.width < 700 ? '100vh' : 'auto'}`,
            borderRadius: `${size.width < 700 ? '0' : '30.4225px'}`,
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -55%)',
            padding: '45px 33px'
        },
        overlay: {
            zIndex: 100000,
            background: 'rgba(26, 26, 26, .3)'
        }
    };

    return modalStyles
}

export default ModalStyles