import React, { lazy, Suspense } from 'react'
import Loader from 'components/Reusable/Loader'

const Paper = lazy(() => import('components/Sections/Paper'))
const Footer = lazy(() => import('components/Layout/Footer'))

function Terms() {
    const loader = () => <Loader />

    const MainComponent = () => (
        <Suspense fallback={loader()}>
            <Paper />
            <Footer />
        </Suspense>
    )

    return MainComponent()
}

export default Terms