import React from 'react'; 
import NotifiedHero from 'components/pages/Vans/notifiedHero';  
import VanInformation from 'components/pages/Vans/vanInformation';  
 

const Vans = () => {

    return ( 
        <>
            <NotifiedHero /> 
            <VanInformation /> 
        </> 
    );
}

export default Vans;
