import React, { lazy, Suspense } from 'react'
import Loader from 'components/Reusable/Loader'

const ThankHero = lazy(() => import('components/Sections/Thank'))
const Footer = lazy(() => import('components/Layout/Footer'))

function Thanks() {
    const loader = () => <Loader />

    const MainComponent = () => (
        <Suspense fallback={loader()}>
            <ThankHero />
            <Footer />
        </Suspense>
    )

    return MainComponent()
}

export default Thanks