import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trigger } from 'components/Store/reducer'
import { makeOrderAgain } from 'components/Store/thunk'

function OrderAgain() {
    const alert = useSelector(state => state.trigger)
	const dispatch = useDispatch()

	const ALLOWED_ALERTS_TYPES = [
		'order-again',
    ]

	useEffect(() => {
		if (ALLOWED_ALERTS_TYPES.includes(alert)) {
			dispatch(trigger(''))
			dispatch(makeOrderAgain())
		}
	}, [alert])

	return null
}

export default OrderAgain