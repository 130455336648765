import React from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import BasketState from 'components/Reusable/DrinkModal/basket-state'
import { drinkmodal } from 'components/Store/reducer'
import clickID from 'components/Reusable/UseClickID'
import {
    Article,
    Footer,
    RouteWrap,
    ContinueShoppingBtn,
    CheckoutBtn
} from './styles'

function Step3(props) {
    const dispatch = useDispatch()

    const handleConfirm = () => {
        dispatch(drinkmodal(false))
        clickID('note-submit')
        clickID('top')

        setTimeout(() => {
            clickID('basket-arr')
        }, 900)

        setTimeout(() => {
            clickID('basket-submit')
            clickID('basket-item')
            props.history.push('/menu')
        }, 1000)
    }

    const checkout = () => {
        dispatch(drinkmodal(false))
        clickID('note-submit')
        props.history.push('/checkout')
        clickID('top')

        setTimeout(() => {
            clickID('basket-arr')
        }, 900)

        setTimeout(() => {
            clickID('basket-submit')
            clickID('basket-item')
        }, 1000)
    }

    return (
        <>
            <Article>
                <div className="modal-main">
                    <BasketState />
                </div>
            </Article>
            <Footer>
                <RouteWrap flex>
                    <ContinueShoppingBtn onClick={() => handleConfirm()}>Continue shopping</ContinueShoppingBtn>
                    <CheckoutBtn onClick={() => checkout()}>Checkout</CheckoutBtn>
                </RouteWrap>
            </Footer>
        </>
    )
}

export default withRouter(Step3)