import React from 'react'
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import OrderState from './order-state'
import LocationsState from './locations-state'
import {
    drinkmodal,
    orderStep,
    additionalPrice,
    staticAdditionalPrice,
    additionalOptions,
} from 'components/Store/reducer';

function BodyState(props) {
    const path = props.location.pathname.slice(1)
    const root0 = path.split('/')[0]
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(orderStep(1))
        dispatch(additionalPrice(0))
        dispatch(staticAdditionalPrice(0))
        dispatch(drinkmodal(false))
        dispatch(additionalOptions([]))
        props.history.push('/menu')

        if (root0 === 'order-completed') {
            props.history.push('/order-completed')
        }
        if (path === 'menu/route') {
            props.history.push('/menu')
        }
    }

    return (
        <>
            <button onClick={() => handleClose()} id='close' />
            {path === 'menu/route' || root0 === 'order-completed' ? <LocationsState /> : <OrderState />}
        </>
    )
}

export default withRouter(BodyState)