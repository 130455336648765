const colors = {
    white: '#fff',
    black: '#000',
    grey: '#9C9C9C',
    grey2: '#BABABA',
    grey3: '#CACACA',
    grey4: '#C0C0C0',
    grey5: '#797979',
    grey6: '#A7A7A7',
    primary: '#1D3745',
    cream: '#D09E6F',
    cream2: '#F4EBDC',
    shadow: '0px 0px 6px 1px rgba(0, 0, 0, 0.1)',
    red: '#ff0065',
    redDefault: '#ff0065',
    redLight: '#ffe5ef',
}

export default colors