import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trigger } from "components/Store/reducer";
import { loadUser } from "components/Store/thunk";

function GetUser() {
  const alert = useSelector((state) => state.trigger);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  const ALLOWED_ALERTS_TYPES = [
    "login",
    "update-address",
    "update-name",
    "made-purchase",
    "on-load",
    "update-notifications",
    "update-saved-cards",
  ];

  useEffect(() => {
    if (ALLOWED_ALERTS_TYPES.includes(alert)) {
      dispatch(trigger(""));
      dispatch(loadUser());
    }
  }, [alert]);

  return null;
}

export default GetUser;
