import styled, { css } from "styled-components";
import colors from 'components/Theme/colors'
import { device } from 'components/Theme/device'

export const Button = styled.button`
    width: 166.79px;
    height: 46px;
    padding: 0 10px;
    border: 2px solid ${colors.primary} !important;
    background: ${colors.white};
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;

    ${props => props.state && css`
        pointer-events: none;
    `}

    &:active {
        transform: scale(0.99);
    }

    @media ${device.laptop} { 
        width: 126.49px;
        height: 34.88px;
        position: absolute;
        right: 100px;
    }

    @media ${device.mobileL} { 
        margin-right: 50px;
        right: 10px;
    }
`;

export const OrderNumber = styled.span`
    width: 63.49px;
    height: 29.56px;
    left: 963.51px;
    top: 39px;
    background: ${colors.cream};
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 17px;
    line-height: 17px;
    letter-spacing: 0.13em;
    color: ${colors.white};

    @media ${device.laptop} { 
        width: 48.15px;
        height: 22.42px;
        font-size: 13px;
        line-height: 13px;
    }
`;

export const Price = styled.span`
    font-weight: bold;
    font-size: 17px;
    line-height: 17px;
    letter-spacing: 0.13em;
    color: ${colors.primary};
    padding-left: 11.5px;

    @media ${device.laptop} { 
        font-size: 12px;
        line-height: initial;
    }
`;

export const SearchField = styled.input`
    width: 65%;
    max-width: 843px;
    border: none;
    height: 45px;
    background: #F2F2F2;
    border-radius: 100px;
    font-weight: normal;
    font-size: 18px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: #9C9C9C;
    padding: 0 56px;
    outline: none;

    ${props => props.hidden && css`
        display: none;
    `}

    ${props => props.state === 'center' && css`
        width: 100%;
    `}

    @media ${device.laptop} { 
        max-width: 100%;
        margin-left: 20px;
    }

    @media ${device.tabletL} { 
        width: 85%;
        // height: 31.67px;
        // font-size: 12px;
        padding: 0 22.5px;
        margin-left: 20px;
    }

    @media ${device.tablet} { 
        width: 75%;
    }

    @media ${device.mobileL} { 
        width: 100%;
        height: 32px;
        font-size: 12px;
        margin-left: 0;
    }
`;

export const MobileWrap = styled.div`
    width: 60%;

    @media ${device.tablet} {
        width: 100%;
    }

    @media ${device.mobileL} { 
        margin: 0 auto;
        max-width: 250px;
        text-align: center;
        margin-left: 20px;
    }
`