import styled, { css, keyframes } from "styled-components"; 
import colors from 'components/Theme/colors'
import { device } from 'components/Theme/device'

const flash = keyframes`
    25%{
        opacity: 1;
    }
    75% {
        opacity: .1;
    }
    100% {
        opacity: 1;
    }
`;

export const Header = styled.header`
    display: flex;
`

export const VanRows = styled.div`
    padding: 120px 0;
    h2{
        margin-bottom: 15px;
        line-height: 1.4;
        font-size: 40px;
        max-width: 520px
    } 
    p{
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 22px;
        margin-right: 10px;
    } 

    @media ${device.laptopL} {
        padding: 80px 0;
    }

    @media ${device.mobileL} {
        position: relative;
        background: #fff;
        padding: 30px 0;
        border-top-right-radius: 50px;

        h2 {
            font-size: 26px;
        }

        p {
            font-size: 15px;
            padding-top: 5px;
        }
    }
`

export const VanInfoBoxes = styled.div`
    > div{
        border-radius: 25px;
        padding: 30px;
        margin-bottom: 30px; 
        box-shadow: ${colors.shadow}
    }

    .working-info{
        .vanName{
            margin-bottom: 0;
            font-size: 28px;
            letter-spacing: 1px;
            font-weight: 500;
        }
        .loc{
            position: relative;
            padding-left: 30px;
            &:before, 
            &:after{ 
                content: ""; 
                border-radius: 25px; 
                background-color: ${colors.cream};
                position: absolute;
                top: 17px;
                transform: translateY(-50%); 
            }
            &:before{ 
                height: 20px;
                width: 20px;    
                left: 0;  
            }
            &:after{ 
                border: 3px solid white;
                height: 16px;
                width: 16px; 
                left: 2px;
            }

        }
        .contact{
            margin: 0;
        }

    }

    @media ${device.mobileL} { 
        padding-top: 30px;

        .working-info {
            .vanName {
                font-size: 12px;
                line-height: 12px;
                font-weight: bold;
            }
        }
    }
`

export const Icon = styled.img`
    animation: ${flash} 5s infinite;
`

export const PopUl = styled.ul`
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
`;

export const PopLi = styled.li`
    height: 31.5px;
    width: 31.5px;
    background: ${colors.primary};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    ${props => !props.isAvailable && css`
        filter: grayscale(100%);
        background: #F0F0F0;
    `}
`;

export const PopWrap = styled.div`
    position: relative;
    transition: All .2s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        & .popout {
            visibility: visible;
        }
    }
`;

export const Popout = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 20px);
    visibility: hidden;
    font-size: 12px;
    width: max-content;
    box-shadow: 0px 0px 8.48276px 1.4px rgba(0, 0, 0, 0.1);
    border-radius: 141.379px;
    background: #fff;
    padding: 13.33px 14.23px 11.69px 14.77px;
    z-index: 20;
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.13em;
    color: #9C9C9C;

    &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8.875px 17.75px 8.875px;
        border-color: transparent transparent #fff transparent;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
    }
`;

export const MenuPageElem = styled.div`
    background-color: white;
    padding-top: 150px;
    position: relative;
    h1{
        margin-bottom: 40px;
        letter-spacing: 1px;
        font-weight: 600;
    }

    @media ${device.mobileL} { 
        padding-top: 30px;
    }
`

export const Img = styled.img`
    transform: scale(.6);
`

export const HeroContainer = styled.div`
    padding: 120px 0;
    h1{
        margin-bottom: 15px;
        line-height: 1.4;
        font-size: 40px;
        max-width: 520px
    } 
    p{
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 22px;
        margin-bottom: 30px;
    } 

    @media ${device.laptopL} {
        padding: 80px 0;
    }

    @media ${device.mobileL} { 
        padding: 30px 0;
        
        h1 {
            font-size: 20px;
        }

        p {
            font-size: 13px;
        }
    }
`

export const BG = styled.img`

    @media ${device.mobileL} { 
        max-width: 100%;
    }
`

export const MapWrap = styled.div`
    width: 100%;
    height: 300px;
    margin-bottom: 30px;
`

export const Unavailable = styled.div`
    width: 100%;
    height: 300px;
    margin-bottom: 30px;
    background: #EDEDED;
    padding: 15px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const Title = styled.h3`
    font-weight: normal;
    font-size: 22px;
    line-height: 123%;
    text-align: center;
    letter-spacing: 0.13em;
    color: #9C9C9C;
`

export const MobileWrap = styled.div`
    @media ${device.mobileL} {
        max-width: 300px;
        margin: 0 auto;
    }
`

export const Wrapper = styled.div` 
    width: 1212px;
    margin: 0px auto;

    @media ${device.laptop} {
        width: 90%;
    }
`

export const ContactPhone = styled.a` 
    color: ${colors.cream};

    &:hover {
        color: ${colors.cream};
    }
`
