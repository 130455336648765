import React from 'react'
import Header from './Header'
import DropMenu from './drop-menu'
import SkewedBG from './skewed-bg'

import { Main } from './styles'

function Wrapper(props) {
    return (
        <>
            <DropMenu />
            <Header />
            <SkewedBG />
            <Main id='page-wrap'>
                {props.children}
            </Main>
        </>
    )
}

export default Wrapper