import styled, { css } from 'styled-components'
import { device } from 'components/Theme/device.js'
import { Link } from 'react-router-dom'
import colors from 'components/Theme/colors'

export const EmailForm = styled.form`
    display: flex;
    flex-direction: column;

    button {
        width: 160px;
        flex-shrink: 0;
        background: #1D3745;
        color: #fff;
        border: none;
        outline: none;
        cursor: pointer;
    }

    @media ${device.mobileL} {
        flex-direction: column;

        button {
            width: 100%;
            margin-top: 20px;
        }
    }
`

export const Input = styled.input`
    border-radius: 25px;
    padding: 5px 20px;
    width: 100%;
    margin-right: 30px; 
    outline: none;
    border: 1px solid lightgrey; 
    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.15);

    &::placeholder { 
        letter-spacing: 1px;
        color: lightgrey;
        font-weight: 500;
    }

    @media ${device.laptop} {
        margin-bottom: 15px;
    }

    @media ${device.mobileL} {
        padding: 7px 17px;
        margin-bottom: 12px;
        font-size: 14px;
    }

    ${props => props.state && props.styles !== '1' && css`
        margin-bottom: 20px;
    `}

    ${props => props.validation && css`
        background: #ffe5ef;
        box-shadow: 0 2px 10px 0 rgba(255, 0, 101, 0.15);
        border: solid 2px #ff0065;

        &::placeholder { 
            color: #ff0065;
        }
    `}
`

export const CheckboxInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
        background-color: ${colors.cream};
        border: 1px solid transparent;
        &:after {
            display: block;
        }
    }
`;

export const CheckboxLabel = styled.label`
    color: ${colors.primary};
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    position: relative;
    cursor: pointer;
    user-select: none;

    ${props => props.state && css`
        position: absolute;
        bottom: 55px;
        right: 35px;
    `}
`;

export const Checkmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #ccc;
    border-radius: 50%;

    &:after {
        content: "";
        position: absolute;
        left: 6px;
        top: 2px;
        width: 6px;
        height: 11px;
        border: solid ${colors.white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        display: none;
    }
`;

export const CheckLabel = styled.span`
    padding-left: 32px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${props => props.state && css`
        flex-direction: column;
    `}

    @media ${device.laptop} {
        flex-direction: column;
    }
`;

export const Footer = styled.div`
    text-align: left;
`;

export const Error = styled.h3`
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: ${colors.red};

    ${props => props.state === 'left' && css`
        text-align: left;
    `}
`;

export const Row = styled.div`
    width: 49%;

    ${props => props.state === '1' && css`
        width: 100%;
    `}

    @media ${device.laptop} {
        width: 100%;
    }
`;

export const Col = styled.div`
    padding-bottom: 13px;

    @media ${device.laptop} {
        padding-bottom: 0;
    }
`;

export const Info = styled.p`
color: red;
`;

export const InfoLink = styled(Link)`
    color: ${colors.cream};

    &:hover {
        color: ${colors.cream};
    }
`;

export const BtnWrap = styled.div`
    display: flex;
    padding-top: 10px;

    @media ${device.mobileL} {
        justify-content: center;
        padding-top: 20px;
    }
`;

export const LogInButton = styled.button`
    padding: 10px 25px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.primary};
    background: ${colors.white};
    border: 1px solid ${colors.primary};
    outline: none;
    font-weight: bold;
    margin-left: 10px;
`;