const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '440px',
    tablet: '768px',
    tabletL: '992px',
    laptop: '1200px',
    laptopL: '1400px',
    desktop: '2560px'
}

export default size