import styled, { keyframes } from 'styled-components'
import colors from 'components/Theme/colors'

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.span`
    display: block;
    position: relative;
    top: 30px;

    &:after {
        content: "";
        display: block;
        margin: 2em auto;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid ${colors.cream};
        border-color: ${colors.cream} transparent ${colors.cream} transparent;
        animation: ${rotate} 1.2s linear infinite;
    }
`;