const initialValues = {
    email: '',
    name: '',
    password: '',
    phone_number: '',
    lat: '',
    long: '',
    sms_notifications: '',
}

export default initialValues