import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API from "components/Store/api";
import { batch } from "react-redux";
import {
  token,
  trigger,
  welcomeBack,
  orderID,
  isFreeCoffee,
} from "components/Store/reducer";

export const loadAllVans = createAsyncThunk(
  "allVans/LOAD",
  async (_, { getState }) => {
    const s = getState();
    const res = await axios.get(`${API}/api/van/all`, {
      headers: {
        Authorization: `Bearer ${s.token}`,
      },
    });

    return res.data.data;
  }
);

export const loadProductData = createAsyncThunk(
  "productData/LOAD",
  async (_, { getState }) => {
    const s = getState();
    const res = await axios.get(`${API}/api/van/details/1`, {
      headers: {
        Authorization: `Bearer ${s.token}`,
      },
    });

    return res.data.data;
  }
);

export const loadUser = createAsyncThunk(
  "user/LOAD",
  async (_, { getState }) => {
    const s = getState();
    const res = await axios.get(`${API}/api/user`, {
      headers: {
        Authorization: `Bearer ${s.token}`,
      },
    });

    return res.data.data;
  }
);

export const loadReceipt = createAsyncThunk(
  "receipt/LOAD",
  async (_, { getState }) => {
    const s = getState();
    const res = await axios.get(
      `${API}/api/generate_recipe/${s.orderID || s.orderDetails.id}`,
      {
        headers: {
          Authorization: `Bearer ${s.token}`,
        },
      }
    );

    return res.data.data.recipe_file;
  }
);

export const makeOrderAgain = createAsyncThunk(
  "orderAgain/POST",
  async (_, { getState }) => {
    const s = getState();
    const data = {
      order_id: s.orderAgain,
    };

    const res = await axios.post(`${API}/api/order_again`, data, {
      headers: {
        Authorization: `Bearer ${s.token}`,
      },
    });

    return res.data.data.basket;
  }
);

export const deleteCard = createAsyncThunk(
  "deleteCard",
  async (id, { getState }) => {
    const s = getState();
    const res = await axios.delete(`${API}/api/delete_card/${id}`, {
      headers: {
        Authorization: `Bearer ${s.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
);

export const freeCoffee = createAsyncThunk(
  "freeCoffee/POST",
  async (_, { getState, dispatch }) => {
    const s = getState();
    const data = {
      order_id: s.orderID,
    };

    const res = await axios.post(`${API}/api/free_coffee`, data, {
      headers: {
        Authorization: `Bearer ${s.token}`,
      },
    });

    dispatch(orderID(res.data.data.order_id));
    dispatch(isFreeCoffee(res.data.success));
    return res.data;
  }
);

export const getOrderDetails = createAsyncThunk(
  "getOrderDetails/LOAD",
  async (_, { getState }) => {
    const s = getState();
    const res = await axios.get(`${API}/api/get_order_details/${s.orderID}`, {
      headers: {
        Authorization: `Bearer ${s.token}`,
      },
    });

    return res.data.data.orderitems;
  }
);
