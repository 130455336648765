import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  paymentID,
  activePayment,
  paymentMethod,
  orderError,
} from "components/Store/reducer";

function PaymentStatus(props) {
  const { history } = props;

  const ID = useSelector((state) => state.paymentID);
  const AP = useSelector((state) => state.activePayment);

  const dispatch = useDispatch();

  useEffect(
    () =>
      history.listen(() => {
        const location = history.location.pathname.slice(1);

        if (location !== "orders") {
          dispatch(paymentMethod(""));
          dispatch(activePayment(false));
        }

        if (ID && AP && location !== "orders") {
          dispatch(paymentID(""));
          dispatch(activePayment(false));
          dispatch(paymentMethod(""));
        }

        if (location !== "checkout") {
          dispatch(activePayment(false));
          dispatch(orderError(""));
        }
      }),
    [ID, AP, history, dispatch]
  );

  return <div />;
}

export default withRouter(PaymentStatus);
