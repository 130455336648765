import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { reset, buildVersion } from 'components/Store/reducer'

function GetMeta() {
    const dispatch = useDispatch()
    const version = useSelector(state => state.buildVersion)
    const meta = document.querySelector("meta[property='og:version']").getAttribute("build-version");

    useEffect(() => {
        if (window.location.hostname !== 'localhost') {
            if (meta !== version) {
                dispatch(reset())
                setTimeout(() => {
                    dispatch(buildVersion(meta))
                }, 3000)
            }
        }
    },[])

    return null
}

export default GetMeta