import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'

const OrderState = () => {
    const orderStep = useSelector(state => state.orderStep)

    const renderBody = () => {
        if (orderStep === 1) return <Step1 />
        if (orderStep === 2) return <Step2 />
        if (orderStep === 3) return <Step3 />
        if (orderStep === 4) return <Step4 />
        return <div />
    }

    return renderBody()
}


export default withRouter(OrderState)