import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { error } from 'components/Store/reducer'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom"
import Cta from 'components/Reusable/Cta'
import VanMap from 'components/Sections/Map'
import clickID from 'components/Reusable/UseClickID'
import API from 'components/Store/api'
import {
    Header,
    VanRows,
    VanInfoBoxes,
    PopWrap,
    Popout,
    PopUl,
    PopLi,
    Img,
    Wrapper,
    MobileWrap,
    ContactPhone
} from './styles'

const VanInformation = () => {
    const menuList = useSelector(state => state.notify)
    const allVans = useSelector(state => state.allVans)
    const availableCategories = useSelector(state => state.notify.available_categories)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(error(''))
    })

    const renderVanInfo = () => {
        const list = allVans.length && allVans
            .filter((_, index) => index === 0)
            .map(item =>
                <div className="working-info" key={item.id}>
                    <p className="vanName">{item.name}</p>
                    <p className="loc"> Current Location: <span className="cream">{item.location}</span></p>
                    <p className="hours">Working Hours: <br /><span className="lightgrey">{item.opening_hours}</span></p>
                    <p className="contact">Contact: <ContactPhone href={`tel:${item.contact}`}>{item.contact}</ContactPhone></p>
                </div>
            )
        return list
    }

    const renderAvailableCategories = () => {
        const list = availableCategories && availableCategories.map(item =>
            <PopLi key={item.id} isAvailable={true}>
                <PopWrap>
                    <Img src={`${API}/images/${item.exists_icon_filename}`} />
                    <Popout className='popout'>{item.name}</Popout>
                </PopWrap>
            </PopLi>
        )
        return <PopUl>{list}</PopUl>
    }

    return (
        <>
            <VanRows>
                <Wrapper>
                    <MobileWrap>
                        <Row>
                            <Col md="6" >
                                <h2>Vanoffee Van</h2>
                            </Col>
                        </Row>
                    </MobileWrap>
                </Wrapper>
                <Wrapper>
                    <MobileWrap>
                        <Row>
                            <Col md="6" >
                                <VanMap />
                            </Col>
                            <Col md="6" >
                                <VanInfoBoxes>
                                    {renderVanInfo()}
                                    <div className="menu-info">
                                        <Header>
                                            <p>Menu</p>
                                            {renderAvailableCategories()}
                                        </Header>
                                        <p className="short-desc lightgrey">{menuList.description ? menuList.description : ''}</p>
                                        <Link to="/menu" onClick={() => clickID('top')}>
                                            <Cta type="primary" tag="button" textcontent="View Menu" />
                                        </Link>
                                    </div>
                                </VanInfoBoxes>
                            </Col>
                        </Row>
                    </MobileWrap>
                </Wrapper>
            </VanRows>
        </>
    );
}

export default VanInformation;
