import React, { lazy, Suspense } from 'react'
import Loader from 'components/Reusable/Loader'

const YourNearestVan = lazy(() => import('components/Sections/YourNearestVan'))
const AllVans = lazy(() => import('components/Sections/AllVans'))
const Footer = lazy(() => import('components/Layout/Footer'))

function Cookie() {
    const loader = () => <Loader />

    const MainComponent = () => (
        <Suspense fallback={loader()}>
            <YourNearestVan />
            <AllVans />
            <Footer />
        </Suspense>
    )

    return MainComponent()
}

export default Cookie