import React, { lazy, Suspense } from 'react'
import Loader from 'components/Reusable/Loader'

const SignIn = lazy(() => import('components/Sections/SignIn'))
const Footer = lazy(() => import('components/Layout/Footer'))

function SignInPage() {
    const loader = () => <Loader />

    const MainComponent = () => (
        <Suspense fallback={loader()}>
            <SignIn />
            <Footer />
        </Suspense>
    )

    return MainComponent()
}

export default SignInPage