import img from 'assets'

const headerNav = [
    {
        id: Math.random(),
        link: '',
        name: 'Home',
        tags: ['all', 'login', 'logout', 'order']
    },
    {
        id: Math.random(),
        link: 'menu',
        name: 'Menu',
        tags: ['all', 'login', 'logout', 'order']
    },
    {
        id: Math.random(),
        link: 'contact',
        name: 'Contact',
        tags: ['all', 'logout', 'order']
    },
    {
        id: Math.random(),
        link: 'account',
        name: 'Account',
        tags: ['all', 'login', 'order']
    },
    {
        id: Math.random(),
        link: 'orders',
        name: 'Order History',
        tags: ['all', 'login']
    },
    {
        id: Math.random(),
        link: 'about',
        name: `About`,
        tags: ['all', 'login', 'logout', 'about']
    },
]

const footerNav = [
    {
        id: Math.random(),
        href: 'mailto:info@vanoffee.co.uk',
        src: img.iconEmail,
        alt: 'email link',
    },
    {
        id: Math.random(),
        href: 'https://www.linkedin.com/company/vanoffee-uk/',
        src: img.iconLinkedin,
        alt: 'Linkedin link',
    },
    {
        id: Math.random(),
        href: 'https://www.instagram.com/vanoffeeuk/',
        src: img.iconInstagram,
        alt: 'Instgram link',
    },
    {
        id: Math.random(),
        href: 'https://www.facebook.com/VanoffeeUK/',
        src: img.iconFacebook,
        alt: 'Facebook link',
    },
    {
        id: Math.random(),
        href: 'https://twitter.com',
        src: img.iconTwitter,
        alt: 'Twitter link',
    },
    
]

const data = { headerNav, footerNav}

export default data