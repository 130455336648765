import styled, { css } from "styled-components"
import { device } from 'components/Theme/device'
import colors from '../../Theme/colors'

export const CtaContainer = styled.div`

    ${props => props.position === 'center' && css`
        display: flex;
        justify-content: center;
    `}

    @media ${device.mobileL} {
        ${props => props.mob === '100' && css`
            width: 100%;
        `}
    }
`

export const ACta = styled.a`
    width: auto;
    padding: 10px 25px;
    border-radius: 30px;
    display: block;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;

    &.primary{ 
        background-color: ${colors.primary};
        color: white;
        border: 1px solid ${colors.primary};
    }

    &.secondary{
        color: ${colors.primary};
        border: 1px solid ${colors.primary};
        background-color: white;
    }

    &:hover{
        text-decoration: none; 
    }

    @media ${device.mobileL} {
        ${props => props.mob === '100' && css`
            width: 100%;
        `}
    }
`;

export const BCta = styled.button`
    width: auto;
    padding: 10px 25px;
    border-radius: 30px;
    display: block;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &.primary{ 
        background-color: ${colors.primary};
        color: white;
        border: 1px solid ${colors.primary};
    }

    &.secondary{
        color: ${colors.primary};
        border: 1px solid ${colors.primary};
        background-color: white;
    }
    
    &:hover{
        text-decoration: none; 
    }

    ${props => props.position === 'center' && css`
        min-width: 233px;
    `}

    @media ${device.mobileL} {
        ${props => props.mob === '100' && css`
            width: 100%;
        `}
    }
`; 
