const validate = values => {
    const errors = {}

    if (!values.name) {
        errors.name = 'Required'
    }
    if (!values.password) {
        errors.password = 'Required'
    }
    if (!values.email) {
        errors.email = true;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = true;
      }

    return errors
}

export default validate