import React from 'react'
import { withRouter } from 'react-router-dom'
import RenderOptions from './kit/options'
import RenderPrice from './kit/price'
import RenderQuantity from './kit/quantity'
import RenderTitle from './kit/title'
import {
    BasketRow,
    BasketCol,
} from './styles'

function Basket(props) {
    const { modal, data, active, dataArr } = props

    return (
        <BasketRow>
            <BasketCol width='15%'>
                <RenderQuantity data={data} active={active} />
            </BasketCol>

            <BasketCol width='70%'>
                <RenderTitle data={data} />
            </BasketCol>

            <BasketCol width='15%' modal={modal}>
                <RenderPrice data={data} active={active} dataArr={dataArr} />
            </BasketCol>

            <BasketCol width='15%' />

            <BasketCol width='70%'>
                <RenderOptions data={data} />
            </BasketCol>

            <BasketCol width='15%' />
        </BasketRow>
    )
}

export default withRouter(Basket)