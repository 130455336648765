import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { totalPrice, price } from "components/Store/reducer";

function RenderPrice(props) {
  const { data, dataArr } = props;
  const path = props.location.pathname.slice(1);

  const quantity = useSelector((state) => state.quantity);
  const bPrice = useSelector((state) => state.product.price);

  const dispatch = useDispatch();

  const renderItemPrice = () => {
    if (data) {
      const price = data.product.price;
      const quantity = data.quantity;
      const additionalPrice = data.staticAdditionalPrice;

      const itemPrice =
        Number(price) * Number(quantity) +
        Number(additionalPrice) * Number(quantity);

      return itemPrice.toFixed(2);
    }
    return "00.00";
  };

  const renderBasketPrice = () => {
    if (dataArr) {
      const price = dataArr
        .map((x) => +x.product.price * +x.quantity)
        .reduce((a, b) => +a + +b, 0)
        .toFixed(2);

      const additionalPrice = dataArr
        .map((x) => x.staticAdditionalPrice * +x.quantity)
        .reduce((a, b) => +a + +b, 0)
        .toFixed(2);

      const basketPrice = Number(price) + Number(additionalPrice);
      dispatch(totalPrice(basketPrice.toFixed(2)));

      return basketPrice;
    }
    return "00.00";
  };

  renderBasketPrice();

  const renderState = () => {
    if (path === "checkout" && data) {
      return <p>{`£${renderItemPrice()}`}</p>;
    }

    const currentPrice = quantity > 1 ? (bPrice * quantity).toFixed(2) : bPrice;

    dispatch(price(currentPrice));
    return <p>{`£${currentPrice}`}</p>;
  };

  return renderState();
}

export default React.memo(withRouter(RenderPrice));
