import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
    Button,
    OrderNumber,
    Price
} from './styles'

function Basket(props) {
    const basket = useSelector(state => state.basket)
    const totalPrice = useSelector(state => state.totalPrice)
    const activePayment = useSelector(state => state.activePayment)

    const renderState = () => {
        if (basket.length) {
            return (
                <>
                    <Button state={activePayment} onClick={() => props.history.push('/checkout')}>
                        <OrderNumber>{basket.length}</OrderNumber>
                        <Price>£{totalPrice}</Price>
                    </Button>
                </>
            )
        }
        return null
    }

    return renderState()
}

export default withRouter(Basket)