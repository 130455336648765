import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

function useOptions() {
    const [options, setOptions] = useState('')

    const menus = useSelector(state => state.notify.menus)
    const menuId = useSelector(state => state.product.menu_id)
    const additionalOptions = useSelector(state => state.product.additional_options)

    useEffect(() => {
        const additionalOptions2 = menus && menus.filter(x => x.id === menuId).map(x => x.additional_options)[0]
        setOptions(additionalOptions && additionalOptions.length ? additionalOptions : additionalOptions2)
    }, [additionalOptions, menuId, menus])

    return options
}

export default useOptions