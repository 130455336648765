import React from 'react'
import { useSelector } from 'react-redux'
import {
    Left,
    Right,
    Title,
    Subtitle,
    LocationsWrap,
    LocationsList,
    LocationsLi,
    LocationsIcon,
    LocationsTime,
    LocationsTitle
} from './styles'

function LocationsState() {
    const routes = useSelector(state => state.notify.routes)

    const locations = () => {
        const list = routes && routes
            .filter(item => item.status === 2)
            .map(item =>
                <LocationsLi key={item.id}>
                    <Left>
                        <LocationsIcon />
                        <LocationsTime>{item.time}</LocationsTime>
                    </Left>
                    <Right>
                        <LocationsTitle>{item.location_name}</LocationsTitle>
                    </Right>
                </LocationsLi>
            )
        return <LocationsList>{list}</LocationsList>
    }

    return (
        <LocationsWrap>
            <Title>Van One Route</Title>
            <Subtitle>Please note that times and stops are subject to change.</Subtitle>
            {locations()}
        </LocationsWrap>
    )
}

export default LocationsState