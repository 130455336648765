import React from 'react';
import BtnLoader from 'components/Reusable/BtnLoader'
import {
    CtaContainer,
    ACta,
    BCta
} from './styles'

function Cta({tag, type, btnType, textcontent, loader, mob, position }) {
    return ( 
        <CtaContainer mob={mob} position={position}> 
            { tag === "button" ? 
                <BCta className={`${type} ctn-btn`} type={btnType} mob={mob} position={position}>
                    {textcontent}
                    {loader && <BtnLoader />}
                </BCta> : 
                <ACta className={type} mob={mob}>
                    {textcontent}
                </ACta>
            }
        </CtaContainer>
    );
}

export default Cta;