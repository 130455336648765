import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { orderStep } from 'components/Store/reducer'
import data from '../data'
import { Link } from "react-router-dom"
import img from 'assets'
import Search from './search'
import clickID from 'components/Reusable/UseClickID'
import {
    HeaderLink,
    Left,
    Logo,
    Nav,
    NavUl,
    NavLi,
} from '../styles'

function Navigation(props) {
    const path = props.location.pathname.slice(1)
    const jwt = useSelector(state => state.token)
    const userData = useSelector(state => state.userData)

    const scrollToTop = () => {
        dispatch(orderStep(1))
        return clickID('top')
    }

    const dispatch = useDispatch()

    const navigation = () => {
        const list = data.headerNav
        .filter(x => x.tags.indexOf(jwt ? 'login' : 'logout') > -1)
        .filter(x => x.tags.indexOf(userData && userData.user_orders.length ? 'all' : 'order') > -1)
        .map(item =>
            <NavLi key={item.id}>
                <HeaderLink
                    to={'/' + item.link}
                    onClick={() => scrollToTop()}
                    active={item.link === path ? 1 : 0}
                >
                    {item.name}
                </HeaderLink>
            </NavLi>
        )
        return <NavUl>{list}</NavUl>
    }

    const renderState = () => {
        if (path === 'menu') {
            return (
                <>
                    <Link to="/">
                        <Logo src={img.logo} alt="logo" />
                    </Link>
                    <Search state='center' />
                </>
            )
        }

        return (
            <>
                <Left>
                    <Link to="/">
                        <Logo src={img.logo} alt="logo" />
                    </Link>
                    <Nav>
                        {navigation()}
                    </Nav>
                </Left>
            </>
        )
    }

    return renderState()
}

export default withRouter(Navigation)