import React from 'react';
import { useSelector } from 'react-redux';
import BodyState from './body-state'
import {
    DrinkModalElem,
    CustomModal,
} from './styles'

const DrinkModal = () => {
    const dm = useSelector(state => state.drinkmodal)

    return (
        <DrinkModalElem className={`modal-overlay ${dm ? 'show-overlay' : ''}`}     >
            <CustomModal className='custom-modal'>
                <BodyState />
            </CustomModal>
        </DrinkModalElem>
    );
}


export default DrinkModal