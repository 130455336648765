import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Cta from "components/Reusable/Cta";
import { useSelector, useDispatch } from 'react-redux'
import ScrollTo from '../scroll-to'
import Basket from './basket'
import Search from './search'
import Navigation from './nav'
import clickID from 'components/Reusable/UseClickID'
import {
    signup,
    token,
    activePayment,
    paymentID,
    basket,
    price,
    totalPrice,
    calculatedPrice,
    orderID,
    selectedLocationTime,
    paymentMethod
} from 'components/Store/reducer'
import {
    Wrapper,
    Inner,
    Wrap,
    HeaderCtas,
    HeaderElem,
    Href,
    Right,
    LogOutBtn
} from '../styles'

function Header(props) {
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])
    const dispatch = useDispatch()
    const jwt = useSelector(state => state.token)

    const renderBasket = () => {
        if (true) {
            return <Basket />
        }
    }

    const handleLogOut = () => {
        dispatch(token(''))
        dispatch(basket(''))
        dispatch(activePayment(false))
        dispatch(paymentID(''))
        dispatch(price(''))
        dispatch(totalPrice(''))
        dispatch(calculatedPrice(''))
        dispatch(orderID(0))
        dispatch(selectedLocationTime(''))
        dispatch(paymentMethod(''))
        props.history.push('/')
    }

    const renderHeaderCtas = () => {
        if (jwt) {
            return (
                <>
                    {renderBasket()}
                    <HeaderCtas onClick={() => forceUpdate()}>
                        <LogOutBtn onClick={() => handleLogOut()} >Log out</LogOutBtn>
                    </HeaderCtas>
                </>
            )
        }
        return (
            <HeaderCtas>
                <Href to="/sign-in" onClick={() => clickID('top')}>
                    <Cta tag="button" type="secondary" textcontent="Sign in" />
                </Href>
                <Href to="/sign-up" onClick={() => {
                    dispatch(signup('step-1'))
                    clickID('top')
                }}>
                    <Cta tag="button" type="primary" textcontent="Join now" />
                </Href>
            </HeaderCtas>
        )
    }

    return (
        <HeaderElem state={false}>
            <ScrollTo />
            <Search hidden />
            <Inner>
                <Wrapper>
                    <Wrap>
                        <Navigation />
                        <Right>
                            {renderHeaderCtas()}
                        </Right>
                    </Wrap>
                </Wrapper>
            </Inner>
        </HeaderElem>
    );
}

export default withRouter(Header);
