import React, { lazy, Suspense, useEffect } from "react";
import { basket } from "components/Store/reducer";
import { useDispatch } from "react-redux";
import Loader from "components/Reusable/Loader";

const OrderCompletedHero = lazy(() =>
  import("components/Sections/OrderCompleted")
);
const Footer = lazy(() => import("components/Layout/Footer"));

function OrderCompleted() {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(basket([]));
  }, []);

  const loader = () => <Loader />;

  const MainComponent = () => (
    <Suspense fallback={loader()}>
      <OrderCompletedHero />
      <Footer />
    </Suspense>
  );

  return MainComponent();
}

export default OrderCompleted;
