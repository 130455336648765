import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadProductData } from 'components/Store/thunk'
import { trigger } from 'components/Store/reducer'

function VansController() {
	const alert = useSelector(state => state.trigger)
	const dispatch = useDispatch()

	const ALLOWED_ALERTS_TYPES = [
		'order-again',
    ]

	useEffect(() => {
		dispatch(loadProductData())
	},[])

	useEffect(() => {
		if (ALLOWED_ALERTS_TYPES.includes(alert)) {
			dispatch(trigger(''))
			dispatch(loadProductData())
		}
	}, [alert])

	return null
}

export default VansController