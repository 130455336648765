import React from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import ModalStyles from "components/Reusable/ModalStyles";
import { welcomeBack } from "components/Store/reducer";
import img from "assets";
import { CloseButton, Title, Info, Button } from "./styles";

function WelcomeBack() {
  const styles = ModalStyles();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.welcomeBack);
  const user = useSelector((state) => state.userData.user);

  function closeModal() {
    dispatch(welcomeBack(false));
  }

  const points = user ? 9 - user.vanoffee_points : "";
  const userName = user ? user.name : "";

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={styles}>
      <CloseButton src={img.iconClose} onClick={closeModal} />
      <Title>Welcome back, {userName}</Title>
      {points === 0 ? (
        <Info>Good news, your free coffee is now ready to collect!</Info>
      ) : (
        <Info>
          You are only {points} coffees away from your next free coffee!
        </Info>
      )}

      <Button onClick={closeModal}>Continue</Button>
    </Modal>
  );
}

export default WelcomeBack;
