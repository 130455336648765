import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initial-state";
import {
  loadAllVans,
  loadProductData,
  loadUser,
  loadReceipt,
  makeOrderAgain,
  getOrderDetails,
} from "components/Store/thunk";

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    token: (state, action) => {
      state.token = action.payload;
    },
    email: (state, action) => {
      state.email = action.payload;
    },
    basket: (state, action) => {
      state.basket = action.payload;
    },
    counter: (state, action) => {
      state.counter = action.payload;
    },
    signup: (state, action) => {
      state.signup = action.payload;
    },
    searchfaq: (state, action) => {
      state.searchfaq = action.payload;
    },
    contact: (state, action) => {
      state.contact = action.payload;
    },
    drinkmodal: (state, action) => {
      state.drinkmodal = action.payload;
    },
    product: (state, action) => {
      state.product = action.payload;
    },
    notify: (state, action) => {
      state.notify = action.payload;
    },
    error: (state, action) => {
      state.error = action.payload;
    },
    note: (state, action) => {
      state.note = action.payload;
    },
    quantity: (state, action) => {
      state.quantity = action.payload;
    },
    price: (state, action) => {
      state.price = action.payload;
    },
    totalPrice: (state, action) => {
      state.totalPrice = action.payload;
    },
    additionalPrice: (state, action) => {
      state.additionalPrice = action.payload;
    },
    staticAdditionalPrice: (state, action) => {
      state.staticAdditionalPrice = action.payload;
    },
    calculatedPrice: (state, action) => {
      state.calculatedPrice = action.payload;
    },
    additionalOptions: (state, action) => {
      state.additionalOptions = action.payload;
    },
    orderStep: (state, action) => {
      state.orderStep = action.payload;
    },
    orderStatus: (state, action) => {
      state.orderStatus = action.payload;
    },
    orderError: (state, action) => {
      state.orderError = action.payload;
    },
    orderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    orderID: (state, action) => {
      state.orderID = action.payload;
    },
    basketPrices: (state, action) => {
      state.basketPrices = action.payload;
    },
    productSum: (state, action) => {
      state.productSum = action.payload;
    },
    fixedPrice: (state, action) => {
      state.fixedPrice = action.payload;
    },
    currentQuantity: (state, action) => {
      state.currentQuantity = action.payload;
    },
    selectedLocationID: (state, action) => {
      state.selectedLocationID = action.payload;
    },
    selectedLocationTime: (state, action) => {
      state.selectedLocationTime = action.payload;
    },
    paymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    paymentID: (state, action) => {
      state.paymentID = action.payload;
    },
    activePayment: (state, action) => {
      state.activePayment = action.payload;
    },
    filteredMenus: (state, action) => {
      state.filteredMenus = action.payload;
    },
    trigger: (state, action) => {
      state.trigger = action.payload;
    },
    filteredFaq: (state, action) => {
      state.filteredFaq = action.payload;
    },
    userData: (state, action) => {
      state.userData = action.payload;
    },
    allVans: (state, action) => {
      state.allVans = action.payload;
    },
    isFreeCoffee: (state, action) => {
      state.isFreeCoffee = action.payload;
    },
    receipt: (state, action) => {
      state.receipt = action.payload;
    },
    userName: (state, action) => {
      state.userName = action.payload;
    },
    orderAgain: (state, action) => {
      state.orderAgain = action.payload;
    },
    welcomeBack: (state, action) => {
      state.welcomeBack = action.payload;
    },
    buildVersion: (state, action) => {
      state.buildVersion = action.payload;
    },
    errorLocation: (state, action) => {
      state.errorLocation = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [loadAllVans.fulfilled]: (state, { payload }) => {
      state.allVans = payload;
    },
    [loadProductData.fulfilled]: (state, { payload }) => {
      state.notify = payload;
    },
    [loadUser.fulfilled]: (state, { payload }) => {
      state.userData = payload;
    },
    [loadReceipt.fulfilled]: (state, { payload }) => {
      state.receipt = payload;
    },
    [makeOrderAgain.fulfilled]: (state, { payload }) => {
      state.basket = payload;
    },
    [getOrderDetails.fulfilled]: (state, { payload }) => {
      state.basket = payload;
    },
  },
});

export const reducer = rootSlice.reducer;

export const {
  token,
  core,
  email,
  basket,
  counter,
  signup,
  searchfaq,
  contact,
  drinkmodal,
  product,
  notify,
  error,
  note,
  quantity,
  orderStep,
  orderID,
  orderStatus,
  orderError,
  orderDetails,
  price,
  totalPrice,
  calculatedPrice,
  additionalPrice,
  staticAdditionalPrice,
  additionalOptions,
  basketPrices,
  productSum,
  fixedPrice,
  currentQuantity,
  selectedLocationID,
  selectedLocationTime,
  paymentMethod,
  paymentID,
  activePayment,
  filteredMenus,
  trigger,
  filteredFaq,
  userData,
  allVans,
  isFreeCoffee,
  receipt,
  orderAgain,
  welcomeBack,
  buildVersion,
  errorLocation,
  reset,
} = rootSlice.actions;
