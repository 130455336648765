import React from 'react'
import { withRouter } from 'react-router-dom'
import Quantity from '../quantity'
import BasketQty from '../basket-qty'

function RenderQuantity(props) {
    const { data, active } = props
    const path = props.location.pathname.slice(1)

    if (path === 'checkout' && data) {
        return <BasketQty data={data} active={active} />
    }
    return <Quantity />
}

export default withRouter(RenderQuantity)