import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import colors from 'components/Theme/colors'
import { device } from 'components/Theme/device.js'

export const Block = styled.div`
    width: 100%;
    padding-top: 58px;
`;

export const MenuNav = styled.div`
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${colors.grey};
    display: flex !important;
    flex-direction: column;
    width: 100%;
`;

export const Mobile = styled.div`
    display: none;
    list-style: none;
    outline: none;

    & .bm-overlay {
        top: 0;
    }

    & .bm-menu-wrap {
        top: 0;
        border-right: 1px solid #fff;
        z-index: 99999 !important;
    }

    & .bm-burger-bars {
        height: 3px !important;
    }

    & .react-burger-menu-btn {
        outline: none !important;
    }

    & svg > path {
        fill: #fff !important;
    }

    & .bm-burger-button {
        position: fixed;
        width: 25px;
        height: 25px;
        right: 40px;
        top: 50px;
        outline: none;
        z-index: 99999 !important;
    }

    @media ${device.laptop} { 
        display: block;
        & .bm-burger-button {
            right: 50px;
            top: 24px;
        }
    }

    @media ${device.mobileL} { 
        & .bm-burger-button {
            right: 20px;
            top: 22px;
        }
    }
`;

export const Styles = {
    bmBurgerBars: {
        background: 'transparent'
    },
    bmBurgerBarsHover: {
        background: 'transparent'
    },
    bmCrossButton: {
        height: '24px',
        width: '24px',
        top: '13px',
        right: '20px'
    },
    bmCross: {
        background: '#fff',
        height: '24px',
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
    },
    bmMenu: {
        background: '#fff',
    },
    bmMorphShape: {
        fill: 'transparent'
    },
    bmItemList: {
        color: '#fff',
        padding: '25px',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    bmItem: {
        display: 'inline-block'
    },
    bmOverlay: {
        background: 'rgba(26, 26, 26, .3)'
    }
}

export const CloseIcon = styled.img`
    width: 22.5px;
    height: 22.5px;
    position: absolute;
    left: 25px;
    top: 25px;
    cursor: pointer;
    outline: none;
`;

export const Logo = styled.img`
    max-width: 167px;
    margin: 0 auto;
    display: block;
`;

export const Nav = styled.nav`
    padding-top: 75px;

    @media ${device.mobileL} { 
        display: none;
    }
`;

export const Ul = styled.ul`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    list-style: none;
`;

export const Li = styled.li`
    margin-bottom: 30px;
`;

export const Ref = styled(Link)`
    color: ${colors.cream};
    text-decoration: none;
    font-size: 17px;
    letter-spacing: 0.13em;

    &:hover {
        text-decoration: underline;
        color: ${colors.primary};
    }

    ${props => props.black && css`
        color: ${colors.primary};
    `}
`;

export const Email = styled.p`
    font-weight: normal;
    font-size: 15px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.grey};
    margin: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 3px 0;
`;

export const Button = styled.button`
    outline: none;
    color: #1D3745;
    border: 2px solid ${colors.primary};
    width: 100%;
    max-width: 322.91px;
    height: 42.36px;
    box-sizing: border-box;
    border-radius: 56.3387px;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.13em;
    margin-bottom: 13px;
    
    ${props => props.state === 'sign-in' && css`
        background: ${colors.white};
        color: ${colors.primary};
    `}

    ${props => props.state === 'join-now' && css`
        background: ${colors.primary};
        color: ${colors.white};
    `}
`;