import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import colors from 'components/Theme/colors'
import { device } from 'components/Theme/device'

export const Main = styled.main`
    margin-top: 100px;

    @media ${device.laptop} { 
        margin-top: 75px;
    }
`;

export const Wrapper = styled.div` 
    width: 1212px;
    margin: 0px auto;

    @media ${device.laptop} {
        width: 90%;
    }
`

export const Row = styled.div` 
    display: flex;
    justify-content: space-between;
`

export const Col = styled.div` 
    max-width: 50%;
`

export const Wrap = styled.div`
    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: center;

    @media ${device.laptop} {
        justify-content: flex-start;
    }
`;

export const OrderedCol = styled.div`
    max-width: 50%;
`;

export const Left = styled.div`
    display: flex;
    align-items: center;
`;

export const Logo = styled.img`
    @media ${device.laptop} { 
        width: 40px;
    }
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
`;

export const HeaderCtas = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 100%; 
    align-items: center;
    > * {
        margin-left: 15px
    }

    @media ${device.laptop} {
        position: absolute;
        right: 90px;
    }

    @media ${device.tabletL} { 
        display: none;
    }
`

export const Href = styled(Link)`
    &:hover {
        text-decoration: none;
    }
`

export const HeaderElem = styled.header`
    overflow-x: hidden;
    padding: 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    box-shadow: rgb(65 65 65 / 50%) 0px 0px 10px;

    ${props => props.state && css`
        box-shadow: rgb(65 65 65 / 50%) 0px 0px 10px;
    `}
`

export const Inner = styled.div`
    background: white;
    position: relative;
    height: 100px;
    display: flex;
    align-items: center;

    @media ${device.laptop} { 
        height: 75px;
    }
`;

export const HeaderLink = styled(Link)`
    padding: 16px 22.5px;
    border-radius: 100px;
    text-decoration: none;
    font-weight: 800;
    color: ${colors.primary};
    position: relative;

    &:hover {
        background: #F4EBDC;
        text-decoration: none;
        color: ${colors.primary};
    }

    ${props => props.active && css`

        &:after {
            content: '';
            background: ${colors.cream};
            position: absolute;
            width: 6px;
            height: 6px;
            right: 10px;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-25%);
        }
    `}
`

export const HeaderSkewed = styled.div`
    height: 700px;
    /* min-height: 500px; */
    width: 200vw;
    position: absolute;
    // left: 50%;
    transform: translateX(-50%) skewY(-10deg);
    top: -20vh;
    background-color: #F4EBDC; 
    z-index: -1;
`

export const FooterElem = styled.footer`
    background-color: #1D3745;
    padding: 50px 0;
    position: relative;

`
export const FooterCol = styled.div`
    @media ${device.tablet} {
        ${props => props.mob && css`
            padding-bottom: 23px;
        `}
    }
`

export const FooterTitle = styled.h3`
    font-weight: bold;
    font-size: 20px;
    line-height: 104%;
    letter-spacing: 0.13em;
    color: ${colors.white};
    margin-bottom: 15px;

    @media ${device.laptop} {
        margin-bottom: 7px;
    }
`

export const Ul = styled.ul`
    display: flex;
    flex-direction: column;
    padding:0;
    margin: 0;
    list-style: none;

    @media ${device.mobileL} {
    }
`

export const Li = styled.li`
    margin-bottom: 10px;
    color: white;
    font-weight: 100;

    @media ${device.laptop} {
        margin-bottom: 4px;
    }
`

export const Nav = styled.nav`
    @media ${device.laptop} { 
        display: none;
    }
`

export const NavUl = styled.ul`
    display: flex;
    padding:0;
    margin: 0;
    list-style: none;
    padding-left: 45px;

    @media ${device.mobileL} {
    }
`

export const NavLi = styled.li`
    margin-right: 20px;

    @media ${device.mobileL} {
    }
`

export const SocialList = styled.ul`
    display: flex;
    align-items: center;
    margin: 0;
    list-style: none;
`

export const SocialLi = styled.li`
    padding-right: 26px;
    margin: 0;

    @media ${device.tablet} {
        padding-right: 15px;
    }
`

export const SocialLink = styled.a`
    text-decoration: none;
    cursor: pointer;
`

export const SocialImg = styled.img`

    @media ${device.tablet} {
        max-width: 14px;
    }
`

export const FooterLink = styled(Link)`
    color: ${colors.white};
    text-decoration: none;
    font-weight: normal;
    font-size: 15px;
    line-height: 129.7%;
    letter-spacing: -0.02em;

    &:hover {
        color: ${colors.white};
        text-decoration: underline;
    }

    @media ${device.laptop} {
        font-size: 10.6012px;
        line-height: 129.7%;
    }
`

export const DevelopedBy = styled.span`
    display: block;
    text-decoration: none;
    color: ${colors.white};
    position: relative;
    display: inline-block;
    font-weight: normal;
    font-size: 15px;
    line-height: 129.7%;
    letter-spacing: -0.02em;
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }

    @media ${device.laptop} {
        font-size: 9px;
        line-height: 129.7%;
        text-align: right;
        display: block;
    }

    @media ${device.mobileL} {
        text-align: left;
        display: block;
    }
`;

export const OPOPLink = styled.a`
    text-decoration: none;
    color: ${colors.white};
    position: relative;
    font-weight: normal;
    font-size: 15px;
    line-height: 129.7%;
    letter-spacing: -0.02em;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: ${colors.white};;
        top: 100%;
        left: 0;
        pointer-events: none;
    }

    &:before {
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    }

    &:after {
        top: calc(100% + 4px);
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.4s 0.1s cubic-bezier(0.2, 1, 0.8, 1);
    }

    &:hover {
        color: ${colors.white};

        &:before {
            transform-origin: 0% 50%;
            transform: scale3d(1, 2, 1);
            transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
        }
    
        &:after {
            transform-origin: 0% 50%;
            transform: scale3d(1, 1, 1);
            transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
        }
    }

    @media ${device.laptop} {
        font-size: 9px;
        line-height: 129.7%;
    }
`;


export const Bottom = styled.div`
    display: flex;
    justify-content: flex-end;

    @media ${device.tablet} { 
    }
`

export const MobileWrap = styled.div`
    @media ${device.mobileL} {
        max-width: 300px;
        margin: 0 auto;
    }
`

export const LogOutBtn = styled.button`
    width: auto;
    padding: 10px 25px;
    border-radius: 30px;
    display: block;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
    color: white;
    
    @media ${device.laptop} {
        display: none;
    }
`;

export const CloseButton = styled.img`
    position: absolute;
    top: 25px;
    right: 25px;
    outline: none;
    cursor: pointer;

    @media ${device.mobileL} {
        top: 25px;
    }
`

export const Title = styled.h2`
    font-weight: bold;
    font-size: 40px;
    line-height: 42px;
    letter-spacing: 0.13em;
    color: ${colors.black};
    text-align: center;

    @media ${device.laptopL} {
        font-weight: bold;
        font-size: 30px;

    @media ${device.mobileL} {
        font-size: 20px;
        padding-top: 50px;
    }
`

export const Info = styled.p`
    line-height: 1.4;
    font-size: 25px;
    max-width: 400px;
    text-align: center;
    margin: 30px auto;

    @media ${device.mobileL} {
        font-size: 22px;
    }
`

export const Button = styled.button`
    width: 245.1px;
    height: 54.56px;
    flex-shrink: 0;
    text-align: center;
    background: ${colors.primary};
    border-radius: 118.607px;
    color: ${colors.white};
    font-weight: bold;
    font-size: 20.1632px;
    line-height: 20px;
    letter-spacing: 0.13em;
    outline: none;
    border: none;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
        transform: scale(0.99);
    }

    @media ${device.mobileL} {
        height: 34.12px;
        font-size: 12px;
        line-height: 12px;
    }
`